import React from 'react';
export const DisabledProcessorIcon = () => {
  return (
    <svg
      width={35}
      height={38}
      viewBox="0 0 35 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1042 3.69882V16.0306C18.1039 16.1163 18.1206 16.2013 18.1533 16.2806C18.186 16.3599 18.234 16.4319 18.2947 16.4926C18.3553 16.5532 18.4273 16.6013 18.5066 16.634C18.5859 16.6667 18.6709 16.6833 18.7567 16.683H25.2488L22.9651 21.2504L23.9373 22.2225L26.8865 16.3242C26.9355 16.2247 26.9585 16.1145 26.9534 16.0038C26.9482 15.893 26.9151 15.7854 26.8571 15.6909C26.7991 15.5965 26.7182 15.5182 26.6217 15.4635C26.5253 15.4088 26.4166 15.3794 26.3058 15.3781H19.4091V0.93241C19.4052 0.787115 19.3534 0.647182 19.2618 0.534392C19.1701 0.421603 19.0437 0.342284 18.9023 0.30878C18.7608 0.275277 18.6123 0.289468 18.4797 0.349147C18.3472 0.408825 18.2381 0.510642 18.1695 0.63875L12.8975 11.1827L13.8762 12.1614L18.1042 3.69882Z"
        fill="#787878"
      />
      <path
        d="M34.8059 33.8112C26.9267 25.9269 9.1484 8.14953 1.18899 0.194485C1.05609 0.0676818 0.878858 -0.00210249 0.695181 4.82791e-05C0.511503 0.00219905 0.335954 0.0761144 0.206058 0.205994C0.076161 0.335875 0.00222338 0.511415 4.93296e-05 0.695092C-0.00212472 0.878769 0.0676373 1.05601 0.194423 1.18893L11.246 12.2405L7.42207 19.8883C7.3695 19.9951 7.34483 20.1134 7.35035 20.2322C7.35587 20.3511 7.39141 20.4666 7.45365 20.568C7.51589 20.6694 7.6028 20.7534 7.70628 20.8121C7.80975 20.8708 7.92642 20.9024 8.04539 20.9038H15.4481V36.4096C15.448 36.5671 15.5013 36.72 15.5992 36.8434C15.6972 36.9668 15.834 37.0533 15.9874 37.089C16.141 37.1253 16.3024 37.1092 16.4458 37.0432C16.5892 36.9772 16.7064 36.8651 16.7788 36.7248L23.0959 24.0904L33.8113 34.8058C33.9443 34.9324 34.1215 35.0021 34.3051 34.9999C34.4887 34.9976 34.6641 34.9237 34.7939 34.7939C34.9238 34.664 34.9977 34.4886 34.9999 34.305C35.0022 34.1214 34.9325 33.9442 34.8059 33.8112Z"
        fill="#787878"
      />
    </svg>
  );
};
