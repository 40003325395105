import React from 'react';
import PropTypes from 'prop-types';

export const LockIcon = ({ width = 18, height = 20, color = '#444445' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 18 20"
    fill="none"
  >
    <path
      fill={color}
      d="M12 2H2v16h14V6h-4V2ZM0 .992C0 .444.447 0 .999 0H13l5 5v13.992A1 1 0 0 1 17.007 20H.993A1 1 0 0 1 0 19.008V.992ZM12 9h1v5H5V9h1V8a3 3 0 1 1 6 0v1Zm-2 0V8a1 1 0 1 0-2 0v1h2Z"
    />
  </svg>
);

LockIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};
