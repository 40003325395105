import React from 'react';
// import PropTypes from 'prop-types';
// width = 150, height = 150
export const UserUploadIcon = () => (
  <svg
    width={150}
    height={150}
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M57.5 115C46.1276 115 35.0106 111.628 25.5547 105.31C16.0989 98.9913 8.72899 90.0111 4.37695 79.5043C0.0249139 68.9976 -1.11378 57.4362 1.10487 46.2823C3.32352 35.1284 8.79986 24.8829 16.8414 16.8414C24.8829 8.79986 35.1284 3.32352 46.2823 1.10487C57.4362 -1.11378 68.9976 0.0249139 79.5043 4.37695C90.0111 8.72899 98.9913 16.0989 105.31 25.5547C111.628 35.0106 115 46.1276 115 57.5C115 72.75 108.942 87.3753 98.1587 98.1587C87.3753 108.942 72.75 115 57.5 115ZM57.5 8.84618C47.8772 8.84618 38.4705 11.6997 30.4694 17.0458C22.4683 22.392 16.2322 29.9907 12.5497 38.881C8.86724 47.7713 7.90373 57.554 9.78105 66.9919C11.6584 76.4298 16.2922 85.0991 23.0966 91.9035C29.9009 98.7078 38.5702 103.342 48.0081 105.219C57.446 107.096 67.2287 106.133 76.119 102.45C85.0094 98.7678 92.608 92.5317 97.9542 84.5306C103.3 76.5296 106.154 67.1228 106.154 57.5C106.154 44.5962 101.028 32.2209 91.9035 23.0966C82.7791 13.9722 70.4038 8.84618 57.5 8.84618Z"
      fill="#D0D5DF"
    />
    <path
      d="M57.5 0C46.1276 0 35.0106 3.37232 25.5547 9.6905C16.0989 16.0087 8.72899 24.9889 4.37695 35.4957C0.0249139 46.0024 -1.11378 57.5638 1.10487 68.7177C3.32352 79.8716 8.79986 90.1171 16.8414 98.1586C24.8829 106.2 35.1284 111.676 46.2823 113.895C57.4362 116.114 68.9976 114.975 79.5043 110.623C90.0111 106.271 98.9913 98.9011 105.31 89.4453C111.628 79.9894 115 68.8724 115 57.5C115 42.25 108.942 27.6247 98.1587 16.8414C87.3753 6.05802 72.75 0 57.5 0ZM57.5 26.5385C60.9992 26.5385 64.4198 27.5761 67.3293 29.5201C70.2388 31.4642 72.5065 34.2274 73.8456 37.4602C75.1847 40.6931 75.535 44.2504 74.8524 47.6824C74.1697 51.1143 72.4847 54.2668 70.0104 56.7411C67.536 59.2154 64.3836 60.9005 60.9516 61.5831C57.5196 62.2658 53.9623 61.9154 50.7295 60.5763C47.4966 59.2372 44.7335 56.9696 42.7894 54.0601C40.8453 51.1506 39.8077 47.73 39.8077 44.2308C39.8077 39.5385 41.6717 35.0384 44.9897 31.7204C48.3076 28.4025 52.8077 26.5385 57.5 26.5385ZM57.5 106.154C51.0857 106.15 44.7355 104.878 38.8146 102.411C32.8938 99.9442 27.5192 96.3308 23 91.7788C26.5385 79.6154 40.6923 70.7692 57.5 70.7692C74.3077 70.7692 88.4615 79.6154 92 91.7788C87.4809 96.3308 82.1063 99.9442 76.1854 102.411C70.2645 104.878 63.9143 106.15 57.5 106.154Z"
      fill="#D0D5DF"
    />
    <path
      d="M99.4412 73C88.6265 73 79.8235 81.7994 79.8235 92.6176C79.8235 103.436 88.6265 112.235 99.4412 112.235C110.256 112.235 119.059 103.436 119.059 92.6176C119.059 81.7994 110.256 73 99.4412 73ZM107.83 90.3135C107.481 90.663 107.024 90.8342 106.568 90.8342C106.111 90.8342 105.655 90.6595 105.305 90.3135L101.217 86.2259V103.318C101.217 104.303 100.419 105.102 99.4341 105.102C98.4496 105.102 97.6506 104.303 97.6506 103.318V86.2223L93.563 90.3099C92.8675 91.0054 91.7368 91.0054 91.0413 90.3099C90.3457 89.6144 90.3457 88.4837 91.0413 87.7881L98.1714 80.658C98.3355 80.4939 98.5352 80.362 98.7528 80.2692C99.188 80.0873 99.6802 80.0873 100.115 80.2692C100.336 80.3584 100.533 80.4904 100.697 80.658L107.827 87.7881C108.526 88.4872 108.526 89.6179 107.83 90.3135Z"
      fill="#C52B2B"
    />
  </svg>
);

// UserUploadIcon.propTypes = {
//   width: PropTypes.number,
//   height: PropTypes.number,
// };
