import React from 'react';

const LocalChangesIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99996 18.3327C5.39758 18.3327 1.66663 14.6017 1.66663 9.99935C1.66663 5.39697 5.39758 1.66602 9.99996 1.66602C14.6023 1.66602 18.3333 5.39697 18.3333 9.99935C18.3333 14.6017 14.6023 18.3327 9.99996 18.3327ZM9.99996 16.666C13.6819 16.666 16.6666 13.6813 16.6666 9.99935C16.6666 6.31745 13.6819 3.33268 9.99996 3.33268C6.31806 3.33268 3.33329 6.31745 3.33329 9.99935C3.33329 13.6813 6.31806 16.666 9.99996 16.666ZM9.16663 12.4993H10.8333V14.166H9.16663V12.4993ZM9.16663 5.83268H10.8333V10.8327H9.16663V5.83268Z"
        fill="#C52B2B"
      />
    </svg>
  );
};

export default LocalChangesIcon;
