import React from 'react';
import { PageComingSoon } from '../../shared';

export const GenrateFlow = () => {
  return (
    <>
      <PageComingSoon pageTitle="Generate Flow" />
    </>
  );
};
