import React from 'react';
import PropTypes from 'prop-types';
export const FlashIcon = ({ width = 22, height = 22 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.08564 22.5249C4.8747 23.0171 5.06496 23.5893 5.52958 23.8581C5.99281 24.1256 6.58427 24.0056 6.90688 23.5768L13.4708 14.8236L14.6758 13.5L18.3112 9.22338C18.5524 8.90215 18.591 8.47338 18.4118 8.11492C18.2326 7.75646 17.8659 7.52898 17.4647 7.52898H14.2303L16.825 1.47657C16.9642 1.14844 16.9311 0.773441 16.7353 0.475645C16.6347 0.322612 16.4982 0.201288 16.3397 0.119945C16.1908 0.0427391 16.0239 0 15.8516 0H10.2045C9.75645 0 9.35801 0.281251 9.20774 0.703128L5.0622 11.2914C4.94639 11.6154 4.99465 11.9752 5.19318 12.2565C5.24695 12.3337 5.31037 12.4026 5.38068 12.4619C5.56956 12.6177 5.80807 12.7059 6.05899 12.7059H9.29338L5.08564 22.5249ZM6.70654 11.2059H11.5681L7.91342 19.7343L12.3135 13.8666L13.5494 12.5091L16.5077 9.02898H11.9552L15.1829 1.5H10.5066L6.70654 11.2059ZM6.46459 23.1152C6.46467 23.1151 6.46451 23.1154 6.46459 23.1152Z"
        fill="black"
      />
    </svg>
  );
};
FlashIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
