import PropTypes from 'prop-types';
import React from 'react';

export const ExclamationFailedTestingIcon = ({
  width = 160,
  height = 110,
  color = '#FF7A00',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 167 127"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M140.18 126.963L33.2394 127C27.867 127.002 23.0546 124.226 20.3668 119.574C19.0227 117.248 18.3505 114.696 18.3496 112.144C18.3487 109.592 19.0195 107.039 20.3616 104.712L73.7977 7.435C76.4827 2.7811 81.2932 0.00185864 86.6656 9.31827e-07C92.038 -0.00185677 96.8505 2.77405 99.5386 7.4261L153.049 104.679C154.389 106.999 155.06 109.547 155.06 112.098C155.06 114.649 154.39 117.201 153.047 119.528C150.363 124.182 145.552 126.961 140.18 126.963ZM19.6939 112.142C19.6937 114.465 31.4942 109.459 32.7167 111.575C35.1621 115.807 28.3512 125.66 33.239 125.659C33.239 125.659 149.443 123.091 151.885 118.858C153.106 116.741 153.717 114.419 153.716 112.097C153.715 109.775 153.103 107.454 151.88 105.338L98.3701 8.08505C95.9316 3.86517 91.5538 1.33962 86.6661 1.34131C81.7806 1.343 19.694 109.819 19.6939 112.142Z"
        fill="#3F3D56"
      />
      <path
        d="M117.871 105.511C144.909 105.511 166.828 83.5919 166.828 56.5533C166.828 29.5148 144.909 7.5957 117.871 7.5957C90.8322 7.5957 68.9131 29.5148 68.9131 56.5533C68.9131 83.5919 90.8322 105.511 117.871 105.511Z"
        fill={color}
      />
      <path
        d="M117.88 86.5645C120.363 86.5645 122.375 84.552 122.375 82.0694C122.375 79.5868 120.363 77.5742 117.88 77.5742C115.397 77.5742 113.385 79.5868 113.385 82.0694C113.385 84.552 115.397 86.5645 117.88 86.5645Z"
        fill="white"
      />
      <path
        d="M117.86 26.5411C117.447 29.2848 118.187 29.859 118.191 41.6878C118.195 53.5166 120.649 69.3762 117.875 69.3771C115.1 69.3781 114.085 36.6474 104.45 33.7506C93.1225 30.3447 119.197 17.6589 117.86 26.5411Z"
        fill="white"
      />
      <path
        d="M52.1628 81.5474L47.7396 90.8931C53.0869 87.0587 61.4391 83.7533 68.0856 81.983C61.8928 78.9916 54.315 74.1687 49.7879 69.3936L52.3319 79.2685C22.5054 73.1934 1.08646 50.312 1.07739 24.1011L0 23.7305C0.00946961 51.1084 21.1321 75.3447 52.1628 81.5474Z"
        fill="#3F3D56"
      />
    </svg>
  );
};

ExclamationFailedTestingIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};
