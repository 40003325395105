import React from 'react';
import PropTypes from 'prop-types';

export const RightArrowIcon = ({
  width = 18,
  height = 13,
  color = '#444445',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <path
      fill={color}
      d="M7 10.17 16.192.98l1.414 1.414L7 12.999.636 6.635 2.05 5.221 7 10.171Z"
    />
  </svg>
);

RightArrowIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};
