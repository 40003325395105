import React from 'react';
import PropTypes from 'prop-types';

export const SmallThunderIcon = ({
  width = 8,
  height = 14,
  color = '#444445',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <path fill={color} d="M5 0v6h3l-5 8V8H0l5-8Z" />
  </svg>
);
SmallThunderIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};
