import React from 'react';
export const FlowFiledQuedIcon = () => {
  return (
    <svg
      width={35}
      height={35}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.7969 20.6828C26.6112 20.6828 26.4332 20.6091 26.3019 20.4778C26.1706 20.3465 26.0969 20.1685 26.0969 19.9828V9.40625C26.0882 8.64904 25.7814 7.92578 25.2428 7.39339C24.7043 6.86101 23.9776 6.56245 23.2203 6.5625H18.5937V2.1875H6.5625V4.375H3.33594V6.5625H6.5625V8.75H3.33594V10.9375H6.5625V13.125H18.5937V8.75H23.2203C23.4031 8.75 23.5783 8.8226 23.7076 8.95182C23.8368 9.08105 23.9094 9.25631 23.9094 9.43906V20.0156C23.9123 20.7806 24.2174 21.5133 24.7583 22.0542C25.2992 22.5951 26.0319 22.9002 26.7969 22.9031H31.7188V20.7156L26.7969 20.6828ZM16.4062 10.9375H8.75V4.375H16.4062V10.9375ZM11.4734 19.3375H13.6609V25.7469H11.4734V19.3375ZM11.4734 26.8188H13.6609V29.0063H11.4734V26.8188ZM12.5672 15.4109C10.8366 15.4109 9.14488 15.9241 7.70595 16.8856C6.26702 17.847 5.14551 19.2136 4.48324 20.8125C3.82097 22.4113 3.6477 24.1706 3.98532 25.868C4.32294 27.5653 5.15629 29.1244 6.38 30.3481C7.60371 31.5718 9.16281 32.4052 10.8601 32.7428C12.5575 33.0804 14.3168 32.9072 15.9157 32.2449C17.5145 31.5826 18.8811 30.4611 19.8425 29.0222C20.804 27.5832 21.3172 25.8915 21.3172 24.1609C21.3215 23.0091 21.0984 21.8678 20.6606 20.8024C20.2228 19.737 19.5789 18.7685 18.766 17.9525C17.9531 17.1365 16.987 16.4891 15.9233 16.0473C14.8595 15.6055 13.719 15.3781 12.5672 15.3781V15.4109ZM12.5672 30.7234C11.2692 30.7234 10.0005 30.3386 8.92126 29.6175C7.84206 28.8964 7.00093 27.8714 6.50423 26.6723C6.00753 25.4732 5.87757 24.1537 6.13078 22.8807C6.384 21.6077 7.00902 20.4383 7.9268 19.5205C8.84458 18.6028 10.0139 17.9777 11.2869 17.7245C12.5599 17.4713 13.8794 17.6013 15.0785 18.098C16.2777 18.5947 17.3026 19.4358 18.0237 20.515C18.7448 21.5942 19.1297 22.863 19.1297 24.1609C19.1038 25.8843 18.401 27.5283 17.1731 28.7378C15.9452 29.9473 14.2907 30.6252 12.5672 30.625V30.7234Z"
        fill="#642A00"
      />
    </svg>
  );
};
