const theme = {
  fontRedHat: '"Red Hat Display", sans-serif',
  fontNato: '"Nato Sans", sans-serif',
  colors: {
    // general
    white: '#FFFFFF',
    black: '#000000',
    background: '#FFFFFF',
    lightBackground: '#FFFFFFCC',

    // brand colors
    primary: '#FF7A00',
    darker: '#444445',
    lighter: '#FFF7ED',
    subtle: '#F7FAFC',

    // state colors
    error: '#FF0000',
    warning: '#F3C652',
    info: '#0066FF',
    success: '#06C270',

    darkSuccess: '#38812F',

    // light colors
    lightGrey: '#F5F7FA',
    lightGrey1: '#EBEBF0',
    lightGrey2: '#F3FAFF',
    lightGrey3: '#B9C3D3',
    lightGrey4: '#b8b7b7',

    // dark colors
    darkGrey: '#E0D3D3',
    darkGrey1: '#B5B5BD',
    darkGrey2: '#988C8C',
    darkGrey3: '#EBF0F7',
    darkGrey4: '#425466',

    // variants
    primaryActive: '#E4842B',
    secondaryActive: '#58E715',
    primaryFocus: '#E4842B',
    disabled: '#B5BDC8',
    primaryDisabled: '#FF7A0080',
    secondaryDisabled: '#44444580',
    caution: '#CF9F5D',
    border: '#CCC',
    shadow: '#00000033',
  },
  spacing: {
    xs: '8px',
    sm: '16px',
    md: '24px',
    lg: '32px',
    xl: '40px',
    xl2: '64px',
    xl3: '80px',
    xl4: '96px',
    xl5: '120px',
  },
  size: {
    xs: '10px',
    sm: '12px',
    md: '14px',
    lg: '16px',
    xl: '20px',
    xl2: '24px',
    xl3: '32px',
    xl4: '42px',
    xl5: '48px',
  },
  breakpoint: {
    mobile: '320px',
    tablet: '768px',
    desktop: '1024px',
    desktopHD: '1440px',
  },
  sidebar: '280px',
  header: '78px',
  zIndex: 2,
};
export default theme;
