import React from 'react';
export const UpArrowImageIcon = () => {
  return (
    <svg
      className="upload-icon"
      width={41}
      height={40}
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.4412 0.764648C9.62652 0.764648 0.823547 9.56405 0.823547 20.3823C0.823547 31.2005 9.62652 39.9999 20.4412 39.9999C31.2559 39.9999 40.0588 31.2005 40.0588 20.3823C40.0588 9.56405 31.2559 0.764648 20.4412 0.764648ZM28.8304 18.0781C28.4809 18.4277 28.0243 18.5989 27.5678 18.5989C27.1112 18.5989 26.6546 18.4241 26.3051 18.0781L22.2175 13.9905V31.0828C22.2175 32.0673 21.4185 32.8663 20.4341 32.8663C19.4496 32.8663 18.6506 32.0673 18.6506 31.0828V13.9869L14.563 18.0745C13.8675 18.7701 12.7368 18.7701 12.0413 18.0745C11.3457 17.379 11.3457 16.2483 12.0413 15.5528L19.1714 8.42267C19.3355 8.25859 19.5352 8.12662 19.7528 8.03388C20.188 7.85197 20.6802 7.85197 21.1153 8.03388C21.3365 8.12305 21.5327 8.25502 21.6967 8.42267L28.8269 15.5528C29.526 16.2519 29.526 17.3826 28.8304 18.0781Z"
        fill="#C52B2B"
      />
    </svg>
  );
};
