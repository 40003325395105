import * as React from 'react';
export const LogoFieldIcon = props => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.6667 10.832C14.2685 10.832 12.9596 11.2147 11.8391 11.8809C12.8257 13.0352 13.6058 14.3713 14.1222 15.832H15.6667V10.832ZM12.3365 15.832C10.7039 11.9171 6.84 9.16536 2.33341 9.16536V15.832H12.3365ZM2.33341 7.4987C5.52436 7.4987 8.43608 8.69436 10.6449 10.6621C12.0873 9.71578 13.8127 9.16536 15.6667 9.16536V2.4987H16.5069C16.9634 2.4987 17.3334 2.86949 17.3334 3.32653V16.6709C17.3334 17.128 16.954 17.4987 16.5069 17.4987H1.49325C1.03679 17.4987 0.666748 17.1279 0.666748 16.6709V3.32653C0.666748 2.86933 1.04617 2.4987 1.49325 2.4987H4.00008V0.832031H5.66675V4.16536H2.33341V7.4987ZM14.0001 0.832031V4.16536H7.33341V2.4987H12.3334V0.832031H14.0001ZM12.7501 8.33203C12.0597 8.33203 11.5001 7.77239 11.5001 7.08203C11.5001 6.39167 12.0597 5.83203 12.7501 5.83203C13.4404 5.83203 14.0001 6.39167 14.0001 7.08203C14.0001 7.77239 13.4404 8.33203 12.7501 8.33203Z"
      fill="black"
    />
  </svg>
);
