import React from 'react';
import PropTypes from 'prop-types';

export const LessArrowIcon = ({
  width = 8,
  height = 14,
  color = '#444445',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <path
      fill={color}
      d="M5.171 7 .221 2.05 1.637.638 8 7l-6.364 6.364L.222 11.95 5.172 7Z"
    />
  </svg>
);

LessArrowIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};
