import React from 'react';
import PropTypes from 'prop-types';

export const MicroSoftIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_697_18172)">
      <path
        d="M12.7466 11.25H23.9966V0.75C23.9966 0.336 23.6606 0 23.2466 0H12.7466V11.25Z"
        fill="#4CAF50"
      />
      <path
        d="M11.2466 11.25V0H0.746582C0.332582 0 -0.00341797 0.336 -0.00341797 0.75V11.25H11.2466Z"
        fill="#F44336"
      />
      <path
        d="M11.2466 12.75H-0.00341797V23.25C-0.00341797 23.664 0.332582 24 0.746582 24H11.2466V12.75Z"
        fill="#2196F3"
      />
      <path
        d="M12.7466 12.75V24H23.2466C23.6606 24 23.9966 23.664 23.9966 23.25V12.75H12.7466Z"
        fill="#FFC107"
      />
    </g>
    <defs>
      <clipPath id="clip0_697_18172">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(-0.00341797)"
        />
      </clipPath>
    </defs>
  </svg>
);

MicroSoftIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
