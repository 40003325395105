import PropTypes from 'prop-types';
import React from 'react';

export const RightCircleIcon = ({
  width = 120,
  height = 120,
  color = '#0CBF59',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M64 128C99.3462 128 128 99.3462 128 64C128 28.6538 99.3462 0 64 0C28.6538 0 0 28.6538 0 64C0 99.3462 28.6538 128 64 128Z"
      fill={color}
    />
    <path
      d="M58.5619 87.1443C58.4475 87.1443 58.333 87.1415 58.2182 87.136C57.2061 87.0872 56.2164 86.82 55.3172 86.3528C54.4179 85.8856 53.6304 85.2294 53.0086 84.4292L43.1913 71.8069C42.0482 70.3338 41.536 68.4674 41.7673 66.6171C41.9985 64.7669 42.9542 63.0839 44.4247 61.9373L44.7778 61.6624C46.2511 60.5193 48.1176 60.0073 49.9679 60.2385C51.8183 60.4697 53.5014 61.4253 54.6481 62.8958C55.2014 63.6072 55.9019 64.1907 56.7016 64.6063C57.5013 65.0219 58.3814 65.2598 59.2817 65.3037C60.1819 65.3476 61.0809 65.1964 61.9173 64.8606C62.7536 64.5247 63.5076 64.0122 64.1274 63.3579L84.0616 42.3154C84.6968 41.6449 85.4579 41.106 86.3014 40.7296C87.1449 40.3532 88.0543 40.1466 88.9776 40.1217C89.9009 40.0968 90.8202 40.254 91.6827 40.5843C92.5453 40.9146 93.3344 41.4116 94.0049 42.0469L94.3303 42.3553C95.6843 43.6382 96.4733 45.4064 96.5236 47.271C96.574 49.1356 95.8815 50.9438 94.5987 52.2978L63.6662 84.948C63.0094 85.6419 62.2181 86.1946 61.3404 86.5723C60.4628 86.9499 59.5173 87.1446 58.5619 87.1443Z"
      fill="white"
    />
  </svg>
);

RightCircleIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};
