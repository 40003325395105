import React from 'react';
import PropTypes from 'prop-types';

export const DashboardIcon = ({
  width = 18,
  height = 19,
  color = '#444445',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 17 20"
  >
    <path
      fill={color}
      d="M10 17h6V7.978L9 2.534 2 7.978V17h6v-6h2v6Zm8 1a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V7.49a1 1 0 0 1 .386-.79l8-6.223a1 1 0 0 1 1.228 0l8 6.223a1 1 0 0 1 .386.79V18Z"
    />
  </svg>
);

DashboardIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};
