import React from 'react';
import PropTypes from 'prop-types';

export const GoogleIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_697_18166)">
      <path
        d="M5.55018 14.6231L4.74325 17.6355L1.79395 17.6979C0.912546 16.063 0.412598 14.1926 0.412598 12.2049C0.412598 10.2829 0.880037 8.47036 1.70861 6.87439H1.70924L4.33494 7.35577L5.48516 9.96572C5.24442 10.6676 5.11321 11.421 5.11321 12.2049C5.1133 13.0558 5.26742 13.871 5.55018 14.6231Z"
        fill="#FBBB00"
      />
      <path
        d="M23.3919 10.0394C23.525 10.7406 23.5944 11.4647 23.5944 12.2048C23.5944 13.0346 23.5071 13.8441 23.3409 14.6249C22.7767 17.2818 21.3023 19.6019 19.2599 21.2438L19.2593 21.2431L15.9521 21.0744L15.484 18.1524C16.8392 17.3576 17.8984 16.1138 18.4563 14.6249H12.2583V10.0394H18.5467H23.3919Z"
        fill="#518EF8"
      />
      <path
        d="M19.2588 21.2426L19.2594 21.2432C17.2731 22.8399 14.7498 23.7951 12.003 23.7951C7.58891 23.7951 3.75119 21.328 1.79346 17.6972L5.54968 14.6224C6.52853 17.2348 9.0486 19.0945 12.003 19.0945C13.2729 19.0945 14.4626 18.7512 15.4835 18.1519L19.2588 21.2426Z"
        fill="#28B446"
      />
      <path
        d="M19.4028 3.28209L15.6479 6.35622C14.5913 5.69581 13.3424 5.3143 12.0044 5.3143C8.98308 5.3143 6.41588 7.25927 5.48607 9.96534L1.71011 6.87401H1.70947C3.63854 3.15473 7.52467 0.613647 12.0044 0.613647C14.8168 0.613647 17.3954 1.61545 19.4028 3.28209Z"
        fill="#F14336"
      />
    </g>
    <defs>
      <clipPath id="clip0_697_18166">
        <rect
          width="23.1818"
          height="23.1818"
          fill="white"
          transform="translate(0.412598 0.613647)"
        />
      </clipPath>
    </defs>
  </svg>
);

GoogleIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
