import React from 'react';
export const TotalQuedIcon = () => {
  return (
    <svg
      width={35}
      height={35}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_874_23752)">
        <path
          d="M25.5209 16.0415C20.2942 16.0415 16.0417 20.294 16.0417 25.5207C16.0417 30.7473 20.2942 34.9998 25.5209 34.9998C30.7476 34.9998 35.0001 30.7473 35.0001 25.5207C35.0001 20.294 30.7476 16.0415 25.5209 16.0415ZM28.7395 28.7392C28.4551 29.0236 28.0817 29.1665 27.7084 29.1665C27.3351 29.1665 26.9617 29.0236 26.6774 28.7392L24.4899 26.5517C24.2157 26.279 24.0626 25.9071 24.0626 25.5207V21.8748C24.0626 21.0698 24.7159 20.4165 25.5209 20.4165C26.3259 20.4165 26.9792 21.0698 26.9792 21.8748V24.9169L28.7395 26.6771C29.3097 27.2473 29.3097 28.169 28.7395 28.7392Z"
          fill="#53225B"
        />
        <path
          d="M25.5208 13.125C25.7688 13.125 26.0021 13.125 26.25 13.1396V13.125H25.5208ZM0 21.875H13.6646C15.2396 16.8 19.95 13.125 25.5208 13.125H0V21.875Z"
          fill="#53225B"
        />
        <path
          d="M13.125 25.5207C13.125 25.2728 13.125 25.0394 13.1396 24.7915H0V30.9894C0 33.2061 1.79375 34.9998 4.01042 34.9998H17.5292C14.8313 32.7248 13.125 29.3123 13.125 25.5207Z"
          fill="#53225B"
        />
        <path
          d="M22.2396 0H4.01042C1.79375 0 0 1.79375 0 4.01042V10.2083H26.25V4.01042C26.25 1.79375 24.4563 0 22.2396 0ZM5.10417 7.29167C3.89375 7.29167 2.91667 6.31458 2.91667 5.10417C2.91667 3.89375 3.89375 2.91667 5.10417 2.91667C6.31458 2.91667 7.29167 3.89375 7.29167 5.10417C7.29167 6.31458 6.31458 7.29167 5.10417 7.29167ZM12.3958 7.29167C11.1854 7.29167 10.2083 6.31458 10.2083 5.10417C10.2083 3.89375 11.1854 2.91667 12.3958 2.91667C13.6063 2.91667 14.5833 3.89375 14.5833 5.10417C14.5833 6.31458 13.6063 7.29167 12.3958 7.29167ZM19.6875 7.29167C18.4771 7.29167 17.5 6.31458 17.5 5.10417C17.5 3.89375 18.4771 2.91667 19.6875 2.91667C20.8979 2.91667 21.875 3.89375 21.875 5.10417C21.875 6.31458 20.8979 7.29167 19.6875 7.29167Z"
          fill="#53225B"
        />
      </g>
      <defs>
        <clipPath id="clip0_874_23752">
          <rect width={35} height={35} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
