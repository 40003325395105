import PropTypes from 'prop-types';
import React from 'react';

export const NoDataIcon = ({
  width = 200,
  height = 195,
  color = '#FF7A00',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 200 195"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1376_102068)">
      <path
        d="M126.968 43.8544H73.0768C71.8487 43.8559 70.6714 44.3439 69.803 45.2113C68.9346 46.0787 68.4461 47.2547 68.4446 48.4813V168.117L67.8269 168.305L54.6065 172.349C53.98 172.54 53.3032 172.475 52.7249 172.168C52.1465 171.861 51.7137 171.337 51.5216 170.711L12.1969 42.4078C12.0055 41.7819 12.0708 41.1058 12.3783 40.528C12.6857 39.9501 13.2103 39.5179 13.8367 39.3263L34.2094 33.0954L93.2703 15.0382L113.643 8.80727C113.953 8.71196 114.279 8.67866 114.602 8.70926C114.924 8.73987 115.238 8.83378 115.525 8.98563C115.811 9.13748 116.065 9.34428 116.272 9.5942C116.478 9.84412 116.633 10.1323 116.728 10.4421L126.78 43.2375L126.968 43.8544Z"
        fill="#F5F7FA"
      />
      <path
        d="M138.728 43.2384L126.613 3.71237C126.412 3.05379 126.082 2.44134 125.644 1.91001C125.205 1.37868 124.666 0.93889 124.057 0.615766C123.448 0.292642 122.781 0.0925146 122.095 0.0268285C121.409 -0.0388575 120.716 0.0311845 120.057 0.232945L91.4145 8.99011L32.3567 27.0504L3.71411 35.8107C2.38352 36.2188 1.26941 37.1375 0.616305 38.3653C-0.0367959 39.593 -0.175524 41.0295 0.230578 42.3593L41.6366 177.443C41.9665 178.516 42.6321 179.456 43.5359 180.124C44.4396 180.792 45.5338 181.153 46.658 181.154C47.1783 181.154 47.6957 181.076 48.1927 180.922L67.8272 174.92L68.4448 174.729V174.084L67.8272 174.272L48.0106 180.333C46.8361 180.691 45.5675 180.569 44.4832 179.993C43.3989 179.418 42.5873 178.436 42.2265 177.264L0.823631 42.1773C0.644854 41.5964 0.582546 40.986 0.640271 40.3811C0.697997 39.7762 0.874618 39.1885 1.16003 38.6519C1.44544 38.1152 1.83404 37.6401 2.30357 37.2536C2.77309 36.8672 3.31431 36.577 3.89625 36.3998L32.5389 27.6396L91.5968 9.58236L120.239 0.822109C120.681 0.687558 121.14 0.618954 121.601 0.618524C122.592 0.620745 123.555 0.939679 124.351 1.52861C125.147 2.11754 125.733 2.94552 126.024 3.89128L138.083 43.2384L138.274 43.8553H138.917L138.728 43.2384Z"
        fill="#3F3D56"
      />
      <path
        d="M37.8858 39.4275C37.2905 39.427 36.7111 39.2361 36.2325 38.8826C35.7539 38.5292 35.4013 38.0319 35.2264 37.4636L31.2488 24.4861C31.1419 24.1376 31.1048 23.7713 31.1396 23.4084C31.1745 23.0455 31.2805 22.693 31.4518 22.371C31.623 22.0491 31.8561 21.7639 32.1376 21.532C32.4192 21.3 32.7438 21.1257 33.0928 21.019L87.4254 4.4042C88.1303 4.18936 88.8916 4.26263 89.5424 4.60792C90.1933 4.95321 90.6803 5.54231 90.8968 6.24592L94.8744 19.2235C95.0894 19.9275 95.0159 20.688 94.6703 21.338C94.3246 21.988 93.7349 22.4745 93.0306 22.6908L38.6977 39.3056C38.4346 39.3863 38.161 39.4273 37.8858 39.4275Z"
        fill={color}
      />
      <path
        d="M58.7222 13.8667C62.1333 13.8667 64.8985 11.1047 64.8985 7.69751C64.8985 4.29036 62.1333 1.52832 58.7222 1.52832C55.3111 1.52832 52.5459 4.29036 52.5459 7.69751C52.5459 11.1047 55.3111 13.8667 58.7222 13.8667Z"
        fill={color}
      />
      <path
        d="M58.7223 11.6041C60.8823 11.6041 62.6333 9.85505 62.6333 7.69754C62.6333 5.54003 60.8823 3.79102 58.7223 3.79102C56.5623 3.79102 54.8113 5.54003 54.8113 7.69754C54.8113 9.85505 56.5623 11.6041 58.7223 11.6041Z"
        fill="white"
      />
      <path
        d="M186.103 179.577H81.7238C81.0279 179.576 80.3607 179.3 79.8686 178.808C79.3765 178.317 79.0997 177.65 79.0989 176.955V52.0291C79.0997 51.334 79.3765 50.6676 79.8686 50.176C80.3607 49.6845 81.0279 49.408 81.7238 49.4072H186.103C186.799 49.408 187.467 49.6845 187.959 50.1761C188.451 50.6676 188.728 51.334 188.728 52.0291V176.955C188.727 177.65 188.451 178.317 187.959 178.808C187.466 179.3 186.799 179.576 186.103 179.577Z"
        fill="#F5F7FA"
      />
      <path
        d="M138.083 43.2383H73.077C71.6853 43.2402 70.3511 43.7934 69.367 44.7763C68.3829 45.7593 67.8291 47.092 67.8271 48.4821V174.272L68.4448 174.084V48.4821C68.4463 47.2554 68.9348 46.0794 69.8032 45.212C70.6716 44.3446 71.8489 43.8567 73.077 43.8552H138.274L138.083 43.2383ZM194.75 43.2383H73.077C71.6853 43.2402 70.3511 43.7934 69.367 44.7763C68.3829 45.7593 67.8291 47.092 67.8271 48.4821V189.757C67.8291 191.147 68.3829 192.479 69.367 193.462C70.3511 194.445 71.6853 194.998 73.077 195H194.75C196.142 194.998 197.476 194.445 198.46 193.462C199.444 192.479 199.998 191.147 200 189.757V48.4821C199.998 47.092 199.444 45.7593 198.46 44.7763C197.476 43.7934 196.142 43.2402 194.75 43.2383ZM199.382 189.757C199.381 190.983 198.892 192.159 198.024 193.027C197.156 193.894 195.978 194.382 194.75 194.383H73.077C71.8489 194.382 70.6716 193.894 69.8032 193.027C68.9348 192.159 68.4463 190.983 68.4448 189.757V48.4821C68.4463 47.2554 68.9348 46.0794 69.8032 45.212C70.6716 44.3446 71.8489 43.8567 73.077 43.8552H194.75C195.978 43.8567 197.156 44.3446 198.024 45.212C198.892 46.0794 199.381 47.2554 199.382 48.4821V189.757Z"
        fill="#3F3D56"
      />
      <path
        d="M162.325 56.81H105.502C104.766 56.8092 104.059 56.5165 103.538 55.996C103.017 55.4756 102.724 54.7699 102.723 54.0339V40.4617C102.724 39.7257 103.017 39.02 103.538 38.4996C104.059 37.9791 104.766 37.6864 105.502 37.6855H162.325C163.061 37.6864 163.768 37.9791 164.289 38.4996C164.81 39.02 165.103 39.7257 165.104 40.4617V54.0339C165.103 54.7699 164.81 55.4756 164.289 55.996C163.768 56.5165 163.061 56.8092 162.325 56.81Z"
        fill={color}
      />
      <path
        d="M133.914 38.6108C137.325 38.6108 140.09 35.8488 140.09 32.4417C140.09 29.0345 137.325 26.2725 133.914 26.2725C130.503 26.2725 127.737 29.0345 127.737 32.4417C127.737 35.8488 130.503 38.6108 133.914 38.6108Z"
        fill={color}
      />
      <path
        d="M133.914 36.1998C135.991 36.1998 137.676 34.5175 137.676 32.4422C137.676 30.3669 135.991 28.6846 133.914 28.6846C131.836 28.6846 130.152 30.3669 130.152 32.4422C130.152 34.5175 131.836 36.1998 133.914 36.1998Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1376_102068">
        <rect width="200" height="195" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

NoDataIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};
