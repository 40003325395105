import React from 'react';
import PropTypes from 'prop-types';

export const DownArrowIcon = ({
  width = 13,
  height = 8,
  color = '#444445',
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 13 8"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fill={color}
      d="M7 5.172 2.05.222.638 1.636 7 8l6.364-6.364L11.95.222 7 5.172Z"
    />
  </svg>
);

DownArrowIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};
