import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '../../styles';

export const CalendarIcon = ({
  width = 20,
  height = 20,
  color = theme.colors.darker,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.50002 0.833984V2.50065H12.5V0.833984H14.1667V2.50065H17.5C17.9603 2.50065 18.3334 2.87375 18.3334 3.33398V16.6673C18.3334 17.1276 17.9603 17.5007 17.5 17.5007H2.50002C2.03979 17.5007 1.66669 17.1276 1.66669 16.6673V3.33398C1.66669 2.87375 2.03979 2.50065 2.50002 2.50065H5.83335V0.833984H7.50002ZM16.6667 9.16732H3.33335V15.834H16.6667V9.16732ZM6.66669 10.834V12.5007H5.00002V10.834H6.66669ZM10.8334 10.834V12.5007H9.16669V10.834H10.8334ZM15 10.834V12.5007H13.3334V10.834H15ZM5.83335 4.16732H3.33335V7.50065H16.6667V4.16732H14.1667V5.83398H12.5V4.16732H7.50002V5.83398H5.83335V4.16732Z"
        fill={color}
      />
    </svg>
  );
};

CalendarIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};
