import React from 'react';
export const ExclamationIcon = () => {
  return (
    <svg
      width={77}
      height={58}
      viewBox="0 0 77 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M64.019 57.9831L15.1802 58C12.7267 58.0009 10.5289 56.733 9.30134 54.6086C8.68751 53.5462 8.38053 52.3809 8.38013 51.2152C8.37972 50.0498 8.68605 48.8841 9.29899 47.8214L33.7029 3.39551C34.9291 1.27011 37.126 0.000848827 39.5796 4.25559e-07C42.0331 -0.000847976 44.2309 1.26689 45.4586 3.39145L69.8963 47.806C70.5085 48.8655 70.8148 50.0295 70.8149 51.1944C70.8148 52.3593 70.5086 53.5248 69.8955 54.5876C68.6695 56.7129 66.4726 57.9823 64.019 57.9831ZM8.99404 51.2144C8.99396 52.2754 14.3832 49.9892 14.9415 50.9556C16.0582 52.8883 12.9478 57.3882 15.18 57.3874C15.18 57.3874 68.2495 56.215 69.365 54.2815C69.9226 53.3148 70.2013 52.2542 70.201 51.1939C70.2006 50.1335 69.9212 49.0733 69.3627 48.107L44.9249 3.69238C43.8113 1.76519 41.812 0.611793 39.5798 0.612565C37.3486 0.613337 8.99412 50.1534 8.99404 51.2144Z"
        fill="#3F3D56"
      />
      <path
        d="M53.8309 48.1859C66.1792 48.1859 76.1895 38.1757 76.1895 25.8273C76.1895 13.479 66.1792 3.46875 53.8309 3.46875C41.4826 3.46875 31.4723 13.479 31.4723 25.8273C31.4723 38.1757 41.4826 48.1859 53.8309 48.1859Z"
        fill="#FF7A00"
      />
      <path
        d="M53.8346 39.5335C54.9684 39.5335 55.8875 38.6144 55.8875 37.4806C55.8875 36.3468 54.9684 35.4277 53.8346 35.4277C52.7009 35.4277 51.7817 36.3468 51.7817 37.4806C51.7817 38.6144 52.7009 39.5335 53.8346 39.5335Z"
        fill="white"
      />
      <path
        d="M53.8257 12.1207C53.6372 13.3737 53.9751 13.6359 53.9769 19.0381C53.9788 24.4402 55.0997 31.6832 53.8325 31.6836C52.5653 31.684 52.1019 16.7362 47.7017 15.4132C42.5283 13.8578 54.4362 8.06426 53.8257 12.1207Z"
        fill="white"
      />
      <path
        d="M23.8224 37.2425L21.8023 41.5106C24.2444 39.7594 28.0588 38.2499 31.0942 37.4414C28.266 36.0753 24.8053 33.8727 22.7378 31.6919L23.8996 36.2017C10.278 33.4273 0.496177 22.9775 0.492036 11.0072L0 10.8379C0.0043247 23.3412 9.65086 34.4097 23.8224 37.2425Z"
        fill="#3F3D56"
      />
    </svg>
  );
};
