import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '../../styles';

export const TodoIcon = ({
  width = 20,
  height = 20,
  color = theme.colors.darker,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 60 80"
  >
    <path
      fill={color}
      d="M.017 11.04v65.39A3.574 3.574 0 0 0 3.587 80h51.396a3.574 3.574 0 0 0 3.57-3.57V11.04a3.574 3.574 0 0 0-3.57-3.57h-13.18c-.628-1.308-1.968-2.23-3.519-2.23h-4.898a4.077 4.077 0 0 0 0-2c-.379-1.63-1.75-2.899-3.39-3.18a4.236 4.236 0 0 0-4.828 5.183h-4.899c-1.55 0-2.89.922-3.52 2.231H3.57A3.574 3.574 0 0 0 0 11.044l.017-.004Zm18.347-1.879c0-1.058.86-1.921 1.922-1.921h5.808a1.372 1.372 0 0 0 1.21-2.02 2.168 2.168 0 0 1-.249-1c0-.66.29-1.281.79-1.699a2.266 2.266 0 0 1 1.827-.5c.852.14 1.57.828 1.77 1.672a2.246 2.246 0 0 1-.18 1.531c-.23.43-.21.941.04 1.352.25.41.69.66 1.171.66h5.809c1.058 0 1.922.86 1.922 1.922l.03 3.859-21.858.031v-3.89l-.012.003Zm-.02 5.86h21.891c.98 0 1.79-.719 1.941-1.66h10.5v60.748H5.907V13.361h10.5c.148.941.96 1.66 1.941 1.66h-.003Zm-1.98-3.66H4.904c-.551 0-1 .449-1 1v62.748c0 .55.449 1 1 1h48.76c.551 0 1-.45 1-1V12.361c0-.551-.449-1-1-1h-11.46V9.47h12.78c.871 0 1.57.698 1.57 1.57V76.43c0 .87-.699 1.57-1.57 1.57H3.587c-.87 0-1.57-.7-1.57-1.57V11.04c0-.87.7-1.57 1.57-1.57h12.781v1.89h-.004Z"
    />
    <path
      fill={color}
      d="M20.567 23.751H47.33c.55 0 1-.449 1-1 0-.55-.45-1-1-1H20.567c-.55 0-1 .45-1 1 0 .551.45 1 1 1ZM20.567 32.47H47.33c.55 0 1-.45 1-1s-.45-1-1-1H20.567c-.55 0-1 .45-1 1s.45 1 1 1ZM20.567 41.2H47.33c.55 0 1-.45 1-1 0-.552-.45-1-1-1H20.567c-.55 0-1 .448-1 1 0 .55.45 1 1 1ZM20.567 49.93H47.33c.55 0 1-.449 1-1 0-.55-.45-1-1-1H20.567c-.55 0-1 .45-1 1 0 .551.45 1 1 1ZM20.567 58.66H47.33c.55 0 1-.45 1-1s-.45-1-1-1H20.567c-.55 0-1 .45-1 1s.45 1 1 1ZM20.567 67.38H47.33c.55 0 1-.45 1-1 0-.551-.45-1-1-1H20.567c-.55 0-1 .449-1 1 0 .55.45 1 1 1ZM12.137 24.032l.079.079a.936.936 0 0 0 1.328-.079l3.68-4.14c.558-.621-.329-1.52-.962-.96l-3.39 3.019-.941-.942c-.93-.922-2.329.488-1.41 1.41l1.6 1.602.016.011ZM12.137 32.763l.079.078a.936.936 0 0 0 1.328-.078l3.68-4.14c.558-.622-.329-1.52-.962-.962l-3.39 3.02-.941-.942c-.93-.922-2.329.488-1.41 1.41l1.6 1.602.016.012ZM12.137 41.48l.079.079a.936.936 0 0 0 1.328-.079l3.68-4.14c.558-.621-.329-1.52-.962-.961l-3.39 3.012-.941-.942c-.93-.922-2.329.488-1.41 1.41l1.6 1.602.016.02ZM12.137 50.212l.079.078a.936.936 0 0 0 1.328-.078l3.68-4.14c.558-.622-.329-1.52-.962-.962l-3.39 3.012-.941-.942c-.93-.922-2.329.489-1.41 1.41l1.6 1.602.016.02ZM12.137 60.89l.079.079a.936.936 0 0 0 1.328-.078l3.68-4.14c.558-.622-.329-1.52-.962-.962l-3.39 3.012-.941-.941c-.93-.922-2.329.488-1.41 1.41l1.6 1.601.016.02ZM12.137 69.621l.079.078a.936.936 0 0 0 1.328-.078l3.68-4.14c.558-.622-.329-1.52-.962-.961l-3.39 3.011-.941-.941c-.93-.922-2.329.488-1.41 1.41l1.6 1.602.016.019Z"
    />
  </svg>
);

TodoIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};
