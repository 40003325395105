import React from 'react';
export const ActiveIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.666992L13.4779 1.88431C13.783 1.9521 14 2.22264 14 2.53511V9.19293C14 10.5303 13.3316 11.7793 12.2188 12.5211L8 15.3337L3.7812 12.5211C2.66841 11.7793 2 10.5303 2 9.19293V2.53511C2 2.22264 2.21702 1.9521 2.52205 1.88431L8 0.666992ZM8 2.03285L3.33333 3.06989V9.19293C3.33333 10.0845 3.77893 10.9171 4.5208 11.4117L8 13.7312L11.4792 11.4117C12.2211 10.9171 12.6667 10.0845 12.6667 9.19293V3.06989L8 2.03285ZM10.9683 5.48155L11.9111 6.42435L7.6684 10.667L4.83999 7.83859L5.78281 6.89573L7.66793 8.78093L10.9683 5.48155Z"
        fill="black"
      />
    </svg>
  );
};
