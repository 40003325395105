import React from 'react';

export const DuplicateIcon = () => {
  return (
    <div>
      <svg
        width={120}
        height={120}
        viewBox="0 0 512 512"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_9895_36430)">
          <path
            d="M503.467 256C503.467 392.764 392.764 503.467 256 503.467C205.679 503.467 158.831 488.457 119.893 462.72L59.6735 485.325C39.0484 493.082 18.9268 472.951 26.6751 452.326L49.2799 392.107C23.5433 353.169 8.5332 306.321 8.5332 256C8.5332 119.236 119.236 8.53333 256 8.53333C392.764 8.53333 503.467 119.236 503.467 256Z"
            fill="white"
          />
          <path
            d="M277.333 162.133C298.541 162.133 315.733 144.941 315.733 123.733C315.733 102.526 298.541 85.3334 277.333 85.3334C256.125 85.3334 238.933 102.526 238.933 123.733C238.933 144.941 256.125 162.133 277.333 162.133Z"
            fill="white"
          />
          <path
            d="M220.988 261.589C221.534 259.942 221.815 258.227 221.815 256.495C221.815 246.161 212.139 238.302 202.061 240.759C195.917 242.261 190.498 244.156 186.547 245.709C182.818 247.176 178.637 245.256 177.365 241.468L171.025 222.524C169.958 219.341 171.298 215.893 174.25 214.263C184.729 208.495 210.722 196.267 242.841 196.267H244.096C278.34 196.608 302.797 229.632 294.494 262.733L265.421 361.344C264.875 362.991 264.593 364.706 264.593 366.438C264.593 376.772 274.27 384.631 284.348 382.174C290.492 380.672 295.91 378.778 299.861 377.224C303.59 375.757 307.772 377.677 309.043 381.466L315.383 400.41C316.45 403.592 315.11 407.04 312.158 408.67C301.679 414.438 275.686 426.667 243.567 426.667C243.149 426.667 242.73 426.667 242.312 426.667C208.068 426.325 183.612 393.301 191.914 360.2L220.988 261.589Z"
            fill="white"
          />
          <path
            d="M277.333 170.667C303.206 170.667 324.267 149.606 324.267 123.733C324.267 97.8603 303.206 76.8 277.333 76.8C251.46 76.8 230.4 97.8603 230.4 123.733C230.4 149.606 251.46 170.667 277.333 170.667ZM277.333 153.6C300.5 155.5 241 112.531 241 129C241 145.469 293.803 153.6 277.333 153.6C277.333 170.069 284.5 162 277.333 153.6C260.864 153.6 293 155.5 277.333 153.6Z"
            fill="#FD891F"
          />
          <path
            d="M162.944 225.229L169.276 244.164C170.607 248.175 173.568 251.486 177.391 253.269C181.231 255.07 185.702 255.189 189.679 253.645C192.964 252.348 198.118 250.496 204.083 249.045C207.061 248.311 209.263 249.6 210.304 250.428C212.19 251.912 213.274 254.123 213.274 256.486C213.274 257.297 213.137 258.099 212.881 258.876C212.847 258.978 212.813 259.063 212.787 259.166L183.62 358.118C178.935 376.764 183.014 396.203 194.79 411.46C206.293 426.359 223.573 435.012 242.125 435.191H243.55C277.956 435.191 305.86 421.871 316.262 416.145C322.799 412.535 325.811 404.787 323.447 397.713L317.116 378.778C315.784 374.767 312.823 371.456 309 369.673C305.143 367.863 300.68 367.735 296.712 369.297C293.427 370.594 288.273 372.446 282.308 373.897C279.356 374.639 277.137 373.35 276.087 372.514C274.202 371.029 273.118 368.819 273.118 366.455C273.118 365.645 273.254 364.843 273.604 363.776L302.771 264.823C307.456 246.178 303.377 226.739 291.601 211.482C280.098 196.582 262.818 187.93 244.267 187.75H242.842C208.478 187.75 180.548 201.071 170.129 206.797C163.592 210.406 160.58 218.155 162.944 225.229ZM242.842 204.8H244.019C257.382 204.945 277.101 218.174 285.387 228.901C293.946 239.994 254.311 233.799 251 247L257.331 358.639C256.478 361.165 256.06 363.793 256.06 366.447C256.06 374.093 259.516 381.192 265.549 385.92C271.479 390.596 209.713 411.334 217 409.567C223.033 408.091 297.711 387.174 301.474 385.749L255.889 389.875L210.304 394C200.243 399.385 285.286 336.219 256.06 336.219L204.083 385.92C190.72 385.775 216.602 411.75 208.316 401.024C199.757 389.931 265.689 401.889 269 388.688L276.087 304L268 308C268.819 305.525 273.118 294.594 273.118 292C273.118 284.354 226.901 241.732 220.868 237.005C214.929 232.328 276.087 304.5 289 292C282.967 293.476 293.5 228.901 285.387 228.901L273.604 211.482L252.5 198C262.578 192.615 213.658 204.8 242.842 204.8Z"
            fill="#FD891F"
          />
          <path
            d="M256 0C210.219 0 165.291 12.2112 126.097 35.3195C122.035 37.7088 120.687 42.9483 123.076 47.0016C125.466 51.0549 130.705 52.3947 134.758 50.0224C171.332 28.4672 213.248 17.0752 255.991 17.0752C387.738 17.0752 494.925 124.262 494.925 256.009C494.925 387.755 387.738 494.942 255.991 494.942C208.956 494.942 163.524 481.34 124.595 455.612C122.308 454.11 119.467 453.803 116.89 454.733L56.6613 477.338C50.2101 479.77 43.4432 478.31 38.5621 473.429C33.6811 468.548 32.2389 461.798 34.6709 455.33L57.2672 395.11C58.2229 392.55 57.8987 389.683 56.3883 387.405C30.6517 348.476 17.0581 303.036 17.0581 256.009C17.0581 181.197 51.0635 112.128 110.37 66.5088C114.108 63.6331 114.807 58.2741 111.94 54.5451C109.065 50.7989 103.706 50.1077 99.9765 52.9749C36.4459 101.854 0 175.846 0 256C0 304.913 13.7387 352.23 39.7824 393.139L18.6965 449.314C13.9008 462.029 16.896 475.887 26.496 485.487C36.0875 495.096 49.9285 498.065 62.6688 493.303L118.852 472.209C159.761 498.253 207.078 511.991 255.991 511.991C397.15 511.991 511.991 397.15 511.991 255.991C511.991 114.833 397.158 0 256 0Z"
            fill="#FD891F"
          />
        </g>
        <defs>
          <clipPath id="clip0_9895_36430">
            <rect width={512} height={512} fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
