import React from 'react';
import PropTypes from 'prop-types';
export const BookIcon = ({ color = 'black' }) => {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.083374 3.83301C0.083374 5.90407 1.76231 7.58301 3.83337 7.58301C5.90444 7.58301 7.58337 5.90407 7.58337 3.83301C7.58337 1.76194 5.90444 0.0830078 3.83337 0.0830078C1.76231 0.0830078 0.083374 1.76194 0.083374 3.83301ZM0.083374 12.1663C0.083374 14.2374 1.76231 15.9163 3.83337 15.9163C5.90444 15.9163 7.58337 14.2374 7.58337 12.1663C7.58337 10.0953 5.90444 8.41634 3.83337 8.41634C1.76231 8.41634 0.083374 10.0953 0.083374 12.1663ZM8.41671 12.1663C8.41671 14.2374 10.0956 15.9163 12.1667 15.9163C14.2378 15.9163 15.9167 14.2374 15.9167 12.1663C15.9167 10.0953 14.2378 8.41634 12.1667 8.41634C10.0956 8.41634 8.41671 10.0953 8.41671 12.1663ZM5.91671 3.83301C5.91671 4.9836 4.98397 5.91634 3.83337 5.91634C2.68278 5.91634 1.75004 4.9836 1.75004 3.83301C1.75004 2.68242 2.68278 1.74967 3.83337 1.74967C4.98397 1.74967 5.91671 2.68242 5.91671 3.83301ZM5.91671 12.1663C5.91671 13.3169 4.98397 14.2497 3.83337 14.2497C2.68278 14.2497 1.75004 13.3169 1.75004 12.1663C1.75004 11.0158 2.68278 10.083 3.83337 10.083C4.98397 10.083 5.91671 11.0158 5.91671 12.1663ZM14.25 12.1663C14.25 13.3169 13.3173 14.2497 12.1667 14.2497C11.0161 14.2497 10.0834 13.3169 10.0834 12.1663C10.0834 11.0158 11.0161 10.083 12.1667 10.083C13.3173 10.083 14.25 11.0158 14.25 12.1663ZM11.3334 7.16634V4.66634H8.83337V2.99967H11.3334V0.499674H13V2.99967H15.5V4.66634H13V7.16634H11.3334Z"
          fill={color}
        />
      </svg>
    </>
  );
};
BookIcon.propTypes = {
  color: PropTypes.string,
};
