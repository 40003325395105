import React from 'react';

const RightIcon = () => {
  return (
    <svg
      width="15"
      height="11"
      viewBox="0 0 15 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33309 8.64274L12.9934 0.982422L14.1719 2.16093L5.33309 10.9997L0.0297852 5.69649L1.2083 4.51799L5.33309 8.64274Z"
        fill="#06C270"
      />
    </svg>
  );
};

export default RightIcon;
