import PropTypes from 'prop-types';
import React from 'react';

export const WhiteBoradIcon = ({
  width = 68,
  height = 68,
  color = '#FF7A00',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 67 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1167_38601)">
      <path
        d="M42.5352 14.8424H24.4817C24.0702 14.8429 23.6758 15.008 23.3849 15.3016C23.094 15.5952 22.9304 15.9932 22.9299 16.4084V56.9006L22.723 56.9643L18.2941 58.333C18.0842 58.3976 17.8575 58.3755 17.6638 58.2715C17.47 58.1676 17.325 57.9903 17.2607 57.7786L4.08689 14.3527C4.02278 14.1409 4.04464 13.9121 4.14764 13.7165C4.25065 13.5209 4.42639 13.3746 4.63624 13.3098L11.4611 11.2008L31.2465 5.08918L38.0713 2.98026C38.1751 2.948 38.2843 2.93673 38.3924 2.94709C38.5006 2.95745 38.6057 2.98923 38.7017 3.04063C38.7977 3.09202 38.8827 3.16202 38.9519 3.24661C39.021 3.33119 39.073 3.42872 39.1048 3.53359L42.4722 14.6336L42.5352 14.8424Z"
        fill="#F5F7FA"
      />
      <path
        d="M46.4733 14.6335L42.4148 1.2555C42.3473 1.0326 42.2369 0.825308 42.0899 0.645473C41.943 0.465639 41.7624 0.316787 41.5584 0.207422C41.3545 0.0980564 41.1311 0.030321 40.9012 0.00808884C40.6713 -0.0141434 40.4393 0.00956318 40.2185 0.0778514L30.6232 3.04181L10.8389 9.15453L1.24357 12.1195C0.797826 12.2577 0.424597 12.5686 0.205808 12.9842C-0.0129809 13.3997 -0.0594548 13.8859 0.0765895 14.336L13.9476 60.0566C14.0581 60.4199 14.2811 60.7379 14.5839 60.964C14.8866 61.19 15.2532 61.3122 15.6298 61.3126C15.8041 61.3127 15.9774 61.2863 16.1439 61.2343L22.7215 59.2026L22.9284 59.1379V58.9197L22.7215 58.9834L16.0829 61.0349C15.6894 61.1559 15.2645 61.1145 14.9012 60.9198C14.538 60.725 14.2661 60.3928 14.1452 59.9961L0.275262 14.2744C0.215372 14.0778 0.194499 13.8712 0.213837 13.6665C0.233175 13.4617 0.292343 13.2628 0.387956 13.0812C0.483569 12.8995 0.61375 12.7387 0.77104 12.6079C0.92833 12.4771 1.10964 12.3789 1.30459 12.3189L10.8999 9.35394L30.6843 3.24227L40.2795 0.277261C40.4274 0.23172 40.5811 0.2085 40.7357 0.208355C41.0675 0.209107 41.3904 0.317054 41.657 0.516384C41.9235 0.715715 42.1199 0.995955 42.2172 1.31606L46.257 14.6335L46.3212 14.8423H46.5364L46.4733 14.6335Z"
        fill="#3F3D56"
      />
      <path
        d="M12.6919 13.3442C12.4925 13.3441 12.2984 13.2794 12.1381 13.1598C11.9778 13.0402 11.8596 12.8718 11.8011 12.6795L10.4685 8.28714C10.4327 8.16915 10.4203 8.0452 10.432 7.92237C10.4436 7.79954 10.4792 7.68023 10.5365 7.57125C10.5939 7.46228 10.672 7.36578 10.7663 7.28726C10.8606 7.20874 10.9694 7.14974 11.0863 7.11364L29.2877 1.49017C29.5238 1.41746 29.7789 1.44226 29.9969 1.55912C30.2149 1.67599 30.3781 1.87538 30.4506 2.11352L31.7831 6.50594C31.8551 6.74422 31.8305 7.0016 31.7147 7.22161C31.5989 7.44161 31.4014 7.60628 31.1654 7.67949L12.964 13.303C12.8758 13.3303 12.7841 13.3442 12.6919 13.3442Z"
        fill={color}
      />
      <path
        d="M19.6726 4.69365C20.8153 4.69365 21.7416 3.7588 21.7416 2.60561C21.7416 1.45242 20.8153 0.517578 19.6726 0.517578C18.5299 0.517578 17.6035 1.45242 17.6035 2.60561C17.6035 3.7588 18.5299 4.69365 19.6726 4.69365Z"
        fill={color}
      />
      <path
        d="M19.6715 3.92762C20.3951 3.92762 20.9817 3.33565 20.9817 2.60541C20.9817 1.87518 20.3951 1.2832 19.6715 1.2832C18.9479 1.2832 18.3613 1.87518 18.3613 2.60541C18.3613 3.33565 18.9479 3.92762 19.6715 3.92762Z"
        fill="white"
      />
      <path
        d="M62.3446 60.7802H27.3774C27.1443 60.7799 26.9207 60.6863 26.7559 60.5199C26.591 60.3536 26.4983 60.128 26.498 59.8927V17.6101C26.4983 17.3748 26.591 17.1492 26.7559 16.9829C26.9207 16.8165 27.1443 16.7229 27.3774 16.7227H62.3446C62.5777 16.7229 62.8012 16.8165 62.9661 16.9829C63.1309 17.1492 63.2236 17.3748 63.2239 17.6101V59.8927C63.2236 60.128 63.1309 60.3536 62.966 60.5199C62.8012 60.6863 62.5777 60.7799 62.3446 60.7802Z"
        fill="#F5F7FA"
      />
      <path
        d="M46.2582 14.6348H24.4814C24.0151 14.6354 23.5682 14.8226 23.2385 15.1553C22.9088 15.488 22.7233 15.9391 22.7227 16.4096V58.9846L22.9296 58.9209V16.4096C22.9301 15.9944 23.0937 15.5964 23.3846 15.3028C23.6755 15.0092 24.07 14.8441 24.4814 14.8436H46.3224L46.2582 14.6348ZM65.2419 14.6348H24.4814C24.0151 14.6354 23.5682 14.8226 23.2385 15.1553C22.9088 15.488 22.7233 15.9391 22.7227 16.4096V64.2256C22.7233 64.6961 22.9088 65.1471 23.2385 65.4798C23.5682 65.8125 24.0151 65.9997 24.4814 66.0004H65.2419C65.7081 65.9997 66.1551 65.8125 66.4848 65.4798C66.8144 65.1471 66.9999 64.6961 67.0006 64.2256V16.4096C66.9999 15.9391 66.8144 15.488 66.4848 15.1553C66.1551 14.8226 65.7081 14.6354 65.2419 14.6348ZM66.7937 64.2256C66.7932 64.6407 66.6295 65.0388 66.3386 65.3323C66.0477 65.6259 65.6533 65.7911 65.2419 65.7916H24.4814C24.07 65.7911 23.6755 65.6259 23.3846 65.3323C23.0937 65.0388 22.9301 64.6407 22.9296 64.2256V16.4096C22.9301 15.9944 23.0937 15.5964 23.3846 15.3028C23.6755 15.0092 24.07 14.8441 24.4814 14.8436H65.2419C65.6533 14.8441 66.0477 15.0092 66.3386 15.3028C66.6295 15.5964 66.7932 15.9944 66.7937 16.4096V64.2256Z"
        fill="#3F3D56"
      />
      <path
        d="M54.3786 19.2288H35.3432C35.0963 19.2285 34.8597 19.1294 34.6851 18.9532C34.5106 18.7771 34.4124 18.5383 34.4121 18.2891V13.6955C34.4124 13.4464 34.5106 13.2075 34.6851 13.0314C34.8597 12.8552 35.0963 12.7561 35.3432 12.7559H54.3786C54.6254 12.7561 54.8621 12.8552 55.0366 13.0314C55.2112 13.2075 55.3094 13.4464 55.3096 13.6955V18.2891C55.3094 18.5383 55.2112 18.7771 55.0366 18.9532C54.8621 19.1294 54.6254 19.2285 54.3786 19.2288Z"
        fill={color}
      />
      <path
        d="M44.8601 13.0686C46.0028 13.0686 46.9291 12.1338 46.9291 10.9806C46.9291 9.82742 46.0028 8.89258 44.8601 8.89258C43.7174 8.89258 42.791 9.82742 42.791 10.9806C42.791 12.1338 43.7174 13.0686 44.8601 13.0686Z"
        fill={color}
      />
      <path
        d="M44.8618 12.2526C45.5578 12.2526 46.1221 11.6832 46.1221 10.9808C46.1221 10.2784 45.5578 9.70898 44.8618 9.70898C44.1658 9.70898 43.6016 10.2784 43.6016 10.9808C43.6016 11.6832 44.1658 12.2526 44.8618 12.2526Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1167_38601">
        <rect width="67" height="66" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

WhiteBoradIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};
