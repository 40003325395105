import React from 'react';
import PropTypes from 'prop-types';

export const GreenRightCircleIcon = ({
  width = 140,
  height = 140,
  color = 'white',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 140 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M70 140C108.66 140 140 108.66 140 70C140 31.3401 108.66 0 70 0C31.3401 0 0 31.3401 0 70C0 108.66 31.3401 140 70 140Z"
      fill="#0CBF59"
    />
    <path
      d="M64.0529 95.3127C63.9278 95.3127 63.8025 95.3097 63.677 95.3036C62.5699 95.2502 61.4875 94.958 60.504 94.447C59.5204 93.936 58.659 93.2183 57.9789 92.3431L47.2413 78.5374C45.991 76.9262 45.4308 74.8848 45.6837 72.8611C45.9366 70.8373 46.9819 68.9966 48.5903 67.7425L48.9765 67.4418C50.5879 66.1916 52.6294 65.6315 54.6532 65.8844C56.677 66.1373 58.5179 67.1825 59.7721 68.7908C60.3773 69.569 61.1435 70.2072 62.0182 70.6617C62.8929 71.1163 63.8555 71.3765 64.8401 71.4245C65.8247 71.4725 66.808 71.3072 67.7228 70.9398C68.6376 70.5725 69.4622 70.0119 70.1402 69.2963L91.9431 46.2811C92.6379 45.5477 93.4704 44.9583 94.393 44.5466C95.3155 44.1349 96.3102 43.909 97.3201 43.8817C98.33 43.8544 99.3353 44.0264 100.279 44.3877C101.222 44.749 102.085 45.2926 102.819 45.9874L103.175 46.3247C104.656 47.7278 105.518 49.6618 105.574 51.7012C105.629 53.7406 104.871 55.7183 103.468 57.1993L69.6357 92.9104C68.9174 93.6694 68.0518 94.274 67.0919 94.687C66.132 95.1001 65.0979 95.313 64.0529 95.3127Z"
      fill={color}
    />
  </svg>
);

GreenRightCircleIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};
