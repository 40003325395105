import React from 'react';

export const MinusIcon = () => {
  return (
    <svg
      width={13}
      height={3}
      viewBox="0 0 13 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.901611 1.2832H11.8266"
        stroke="#FF7A00"
        strokeWidth="1.56071"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
