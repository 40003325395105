import * as React from 'react';
export const UploadIcon = props => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.33341 15.8333H16.6667V10H18.3334V16.6667C18.3334 17.1269 17.9603 17.5 17.5001 17.5H2.50008C2.03985 17.5 1.66675 17.1269 1.66675 16.6667V10H3.33341V15.8333ZM10.8334 7.5V13.3333H9.16675V7.5H5.00008L10.0001 2.5L15.0001 7.5H10.8334Z"
      fill="black"
    />
  </svg>
);
