import React from 'react';
export const DeleteDustbinIcon = () => {
  return (
    <svg
      width={146}
      height={117}
      viewBox="0 0 146 117"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100.598 117H43.5848L43.5739 116.68L40.8516 38.8223H103.331L100.598 117ZM44.2244 116.338H99.9585L102.646 39.4844H41.5369L44.2244 116.338Z"
        fill="#3F3D56"
      />
      <path
        d="M57.1849 53.249H52.8484V106.912H57.1849V53.249Z"
        fill="#3F3D56"
      />
      <path d="M74.2595 53.249H69.923V106.912H74.2595V53.249Z" fill="#3F3D56" />
      <path
        d="M91.3341 53.249H86.9976V106.912H91.3341V53.249Z"
        fill="#3F3D56"
      />
      <path
        d="M139.56 61.6857L139.28 61.5089L68.4623 16.7556L74.6053 7.03516L74.8853 7.212L145.703 51.9651L139.56 61.6857ZM69.376 16.5496L139.354 60.7721L144.789 52.1714L74.8116 7.94847L69.376 16.5496Z"
        fill="#3F3D56"
      />
      <path
        d="M122.462 13.1483C120.403 11.8456 118.014 11.1607 115.578 11.175C113.142 11.1893 110.761 11.9022 108.718 13.229C106.675 14.5558 105.056 16.4409 104.052 18.6606C103.048 20.8802 102.702 23.3413 103.055 25.7517L106.432 27.8857C105.583 25.9152 105.388 23.7249 105.874 21.6353C106.36 19.5457 107.503 17.6667 109.134 16.2733C110.766 14.88 112.8 14.0456 114.94 13.8922C117.08 13.7388 119.213 14.2746 121.026 15.421C122.84 16.5675 124.238 18.2643 125.018 20.2632C125.797 22.2621 125.916 24.458 125.357 26.5293C124.798 28.6006 123.591 30.4386 121.912 31.7741C120.233 33.1096 118.17 33.8724 116.026 33.951L119.403 36.0849C121.732 35.37 123.806 34.002 125.381 32.1432C126.956 30.2844 127.964 28.0129 128.286 25.5982C128.609 23.1835 128.232 20.727 127.2 18.5203C126.168 16.3135 124.524 14.4491 122.465 13.1483H122.462Z"
        fill="#3F3D56"
      />
      <path
        d="M28.3162 52.8009L0.506944 33.3106C0.248574 33.1287 0.072784 32.852 0.0180573 32.5408C-0.0366694 32.2296 0.034141 31.9095 0.214966 31.6504L22.042 0.506945C22.2238 0.248576 22.5006 0.0727852 22.8118 0.0180585C23.1229 -0.0366682 23.4431 0.0341322 23.7022 0.214957L51.5115 19.7052C51.7698 19.8871 51.9456 20.1639 52.0004 20.475C52.0551 20.7862 51.9843 21.1063 51.8035 21.3654L29.977 52.5081C29.7953 52.7667 29.5184 52.9428 29.2071 52.9977C28.8958 53.0526 28.5755 52.9818 28.3162 52.8009Z"
        fill="#E32235"
      />
      <path
        d="M34.5703 31.6528L18.2522 20.2162C18.0635 20.084 17.9027 19.9158 17.779 19.7214C17.6552 19.527 17.571 19.3102 17.5311 19.0832C17.4912 18.8562 17.4964 18.6236 17.5463 18.3987C17.5963 18.1737 17.6901 17.9608 17.8224 17.7721C17.9546 17.5834 18.1228 17.4226 18.3172 17.2988C18.5116 17.1751 18.7285 17.0909 18.9554 17.0509C19.1824 17.011 19.415 17.0162 19.6399 17.0662C19.8649 17.1162 20.0778 17.2099 20.2665 17.3422L36.5846 28.7788C36.9657 29.0459 37.2251 29.4534 37.3057 29.9118C37.3863 30.3702 37.2815 30.8418 37.0144 31.2229C36.7473 31.6041 36.3398 31.8635 35.8814 31.9441C35.423 32.0247 34.9514 31.9199 34.5703 31.6528Z"
        fill="white"
      />
      <path
        d="M30.8252 36.9955L14.5072 25.5589C14.3182 25.4267 14.1572 25.2586 14.0333 25.0642C13.9094 24.8698 13.825 24.6528 13.7849 24.4257C13.7449 24.1987 13.75 23.9659 13.7999 23.7408C13.8499 23.5157 13.9437 23.3027 14.0761 23.1139C14.2084 22.9251 14.3766 22.7642 14.5712 22.6404C14.7657 22.5167 14.9827 22.4324 15.2098 22.3926C15.437 22.3528 15.6697 22.3581 15.8947 22.4082C16.1198 22.4584 16.3328 22.5524 16.5215 22.6849L32.8395 34.1214C33.2202 34.3887 33.4791 34.7962 33.5595 35.2544C33.6399 35.7125 33.5351 36.1838 33.2681 36.5647C33.0012 36.9456 32.5939 37.205 32.1358 37.2857C31.6777 37.3665 31.2063 37.2621 30.8252 36.9955Z"
        fill="white"
      />
      <path
        d="M27.0398 42.397L10.7218 30.9604C10.5331 30.8281 10.3723 30.66 10.2485 30.4656C10.1248 30.2712 10.0406 30.0543 10.0006 29.8273C9.96073 29.6004 9.96591 29.3678 10.0159 29.1428C10.0659 28.9179 10.1596 28.7049 10.2919 28.5162C10.4242 28.3275 10.5923 28.1667 10.7867 28.043C10.9811 27.9192 11.198 27.835 11.425 27.7951C11.6519 27.7552 11.8845 27.7603 12.1095 27.8103C12.3344 27.8603 12.5474 27.9541 12.7361 28.0864L29.0541 39.5229C29.4352 39.79 29.6946 40.1976 29.7752 40.656C29.8559 41.1143 29.7511 41.586 29.484 41.9671C29.2169 42.3482 28.8093 42.6076 28.3509 42.6882C27.8926 42.7688 27.4209 42.6641 27.0398 42.397Z"
        fill="white"
      />
      <path
        d="M34.5244 21.0814L28.2039 16.6517C28.015 16.5195 27.854 16.3514 27.73 16.157C27.6061 15.9625 27.5217 15.7456 27.4817 15.5185C27.4416 15.2914 27.4467 15.0587 27.4967 14.8336C27.5466 14.6085 27.6405 14.3955 27.7728 14.2067C27.9051 14.0178 28.0734 13.857 28.2679 13.7332C28.4625 13.6094 28.6795 13.5252 28.9066 13.4854C29.1337 13.4455 29.3664 13.4508 29.5915 13.501C29.8166 13.5511 30.0295 13.6452 30.2182 13.7776L36.5387 18.2074C36.9198 18.4745 37.1792 18.882 37.2598 19.3404C37.3404 19.7988 37.2356 20.2704 36.9685 20.6515C36.7014 21.0326 36.2939 21.292 35.8355 21.3727C35.3771 21.4533 34.9055 21.3485 34.5244 21.0814Z"
        fill="white"
      />
    </svg>
  );
};
