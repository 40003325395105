import React from 'react';
import PropTypes from 'prop-types';
export const FlashCutIcon = ({ width = 22, height = 22 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.40986 22.5249C6.19892 23.0171 6.38918 23.5893 6.85379 23.8581C7.31703 24.1256 7.90849 24.0056 8.2311 23.5768L14.795 14.8236L7.66997 7.69856L6.38642 11.2914C6.27061 11.6154 6.31887 11.9752 6.5174 12.2565C6.57117 12.3337 6.63458 12.4026 6.7049 12.4619C6.89378 12.6177 7.13229 12.7059 7.38321 12.7059H10.6176L6.40986 22.5249ZM11.7415 13.8914L9.23764 19.7343L12.8145 14.9644L11.7415 13.8914ZM7.78881 23.1152C7.78889 23.1151 7.78873 23.1154 7.78881 23.1152ZM9.05603 11.2059L8.28519 10.4351L8.0098 11.2059H9.05603ZM10.532 0.703128L8.56871 6.19718L16.1901 13.8185L19.6354 9.22338C19.8767 8.90215 19.9153 8.47338 19.736 8.11492C19.5568 7.75647 19.1901 7.52898 18.7889 7.52898H15.5545L18.1492 1.47657C18.2884 1.14844 18.2553 0.773441 18.0596 0.475645C17.9589 0.322612 17.8224 0.201288 17.6639 0.119945C17.515 0.0427391 17.3482 0 17.1758 0H11.5287C11.0807 0 10.6822 0.281251 10.532 0.703128ZM16.5071 1.5H11.8401L10.3007 5.80786L13.3522 8.8593L16.5071 1.5ZM13.5218 9.02898H17.9064L16.0276 11.5347L13.5218 9.02898Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5294 19.7649C19.2365 20.0578 18.7616 20.0578 18.4688 19.7649L3.99994 5.29607C3.70705 5.00318 3.70705 4.5283 3.99994 4.23541V4.23541C4.29284 3.94251 4.76771 3.94252 5.0606 4.23541L19.5294 18.7042C19.8223 18.9971 19.8223 19.472 19.5294 19.7649V19.7649Z"
        fill="black"
      />
    </svg>
  );
};
FlashCutIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
