import React from 'react';

export const CollapseSidebarIconRight = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.75 3.75H9.75V14.25H3.75V3.75ZM14.25 14.25H11.25V3.75H14.25V14.25ZM3 2.25C2.58579 2.25 2.25 2.58579 2.25 3V15C2.25 15.4142 2.58579 15.75 3 15.75H15C15.4142 15.75 15.75 15.4142 15.75 15V3C15.75 2.58579 15.4142 2.25 15 2.25H3ZM8.25 9L5.25 6.375V11.625L8.25 9Z"
      fill="#444445"
    />
  </svg>
);
