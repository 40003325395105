import React from 'react';
export const ActiveThreadIcon = () => {
  return (
    <svg
      width={35}
      height={35}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.9573 12.479H34.9998V22.5223H24.9573V12.479Z"
        fill="#52CF84"
      />
      <path
        d="M24.9573 35.0009H34.9998V24.9575H24.9573V35.0009ZM28.7611 26.6362H31.1969V28.761H33.3216V31.1968H31.1969V33.3215H28.7611V31.1968H26.6363V28.761H28.7611V26.6362Z"
        fill="#52CF84"
      />
      <path
        d="M12.4788 12.479V22.5223H22.5221V12.479H12.4788ZM18.7184 20.8433H16.2825V18.7185H14.1579V16.2827H16.2825V14.1579H18.7184V16.2827H20.8431V18.7185H18.7184V20.8433Z"
        fill="#52CF84"
      />
      <path d="M0 12.479H10.0426V22.5223H0V12.479Z" fill="#52CF84" />
      <path
        d="M10.0426 24.9575H0V35.0009H10.0426V24.9575ZM6.2396 33.3215H3.80378V31.1968H1.67909V28.761H3.80378V26.6362H6.2396V28.761H8.36437V31.1968H6.2396V33.3215Z"
        fill="#52CF84"
      />
      <path
        d="M12.4788 24.9575V35.0009H22.5221V24.9575H12.4788ZM18.7184 33.3215H16.2825V31.1968H14.1579V28.761H16.2825V26.6362H18.7184V28.761H20.8431V31.1968H18.7184V33.3215Z"
        fill="#52CF84"
      />
      <path d="M0 0H10.0426V10.0434H0V0Z" fill="#52CF84" />
      <path d="M24.9573 0H34.9998V10.0434H24.9573V0Z" fill="#52CF84" />
      <path d="M12.4788 0H22.5221V10.0434H12.4788V0Z" fill="#52CF84" />
    </svg>
  );
};
