import React from 'react';

export const DfmCollapsedIcon = () => (
  <svg
    className="logo-small"
    width="36"
    height="64"
    viewBox="0 0 36 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.00552 49.5718C6.91809 49.5718 7.71556 49.7486 8.39793 50.1021C9.08852 50.4474 9.6188 50.9489 9.98876 51.6066C10.3669 52.2561 10.556 53.0166 10.556 53.888C10.556 54.7595 10.3669 55.5159 9.98876 56.1571C9.6188 56.7984 9.08852 57.2917 8.39793 57.637C7.71556 57.974 6.91809 58.1426 6.00552 58.1426H3.20615V49.5718H6.00552ZM6.00552 56.9957C7.00852 56.9957 7.77722 56.7244 8.31161 56.1818C8.84599 55.6392 9.11319 54.8746 9.11319 53.888C9.11319 52.8933 8.84599 52.1163 8.31161 51.5573C7.77722 50.9982 7.00852 50.7187 6.00552 50.7187H4.612V56.9957H6.00552ZM16.9932 49.5718V50.7187H13.3552V53.2468H16.1916V54.3936H13.3552V58.1426H11.9494V49.5718H16.9932ZM27.323 49.5718V58.1426H25.9172V52.2725L23.3028 58.1426H22.3286L19.7019 52.2725V58.1426H18.296V49.5718H19.8128L22.8219 56.2928L25.8185 49.5718H27.323Z"
      fill="#444444"
    />
    <path
      d="M5.20867 0H16.2814C19.1614 0 21.6328 0.263573 23.6916 0.794538C25.7504 1.3255 27.6202 2.31486 29.3012 3.77024C33.6694 7.47553 35.8554 13.1061 35.8554 20.6656C35.8554 23.16 35.6357 25.4367 35.1961 27.4994C34.7566 29.5583 34.0819 31.4148 33.1643 33.0688C32.2467 34.7228 31.0747 36.2011 29.6443 37.5075C28.5186 38.5084 27.2887 39.3144 25.9547 39.9179C24.6207 40.5214 23.1827 40.9493 21.6482 41.1937C20.1137 41.4382 18.3827 41.5605 16.4549 41.5605H5.37831C3.83229 41.5605 2.66795 41.3274 1.88916 40.8652C1.10651 40.403 0.59759 39.7498 0.358554 38.9095C0.119518 38.0691 0 36.9766 0 35.6358V5.15686C0 3.34241 0.408675 2.02836 1.22988 1.21473C2.05108 0.401089 3.37735 0 5.20867 0ZM8.5012 6.6619V34.8718H14.9398C16.3508 34.8718 17.4573 34.8336 18.2593 34.7572C19.0612 34.6808 19.8901 34.4937 20.7499 34.1919C21.6096 33.8901 22.3537 33.4661 22.9822 32.916C25.8236 30.5362 27.2463 26.4337 27.2463 20.6122C27.2463 16.5096 26.6217 13.4384 25.3725 11.3986C24.1234 9.35874 22.5812 8.05616 20.7499 7.49845C18.9186 6.94075 16.7055 6.6619 14.1108 6.6619H8.5012Z"
      fill="#FF7A00"
    />
    <path
      d="M22.3706 20.9329C22.3706 19.2451 20.9897 17.877 19.2862 17.877C17.5828 17.877 16.2019 19.2451 16.2019 20.9329C16.2019 22.6206 17.5828 23.9888 19.2862 23.9888C20.9897 23.9888 22.3706 22.6206 22.3706 20.9329Z"
      fill="#FF7A00"
    />
    <path
      d="M35.8664 20.9329C35.8664 19.2451 34.4855 17.877 32.7821 17.877C31.0787 17.877 29.6978 19.2451 29.6978 20.9329C29.6978 22.6206 31.0787 23.9888 32.7821 23.9888C34.4855 23.9888 35.8664 22.6206 35.8664 20.9329Z"
      fill="white"
    />
    <path
      d="M30.2745 20.9316H27.1902"
      stroke="white"
      strokeWidth="1.89723"
      strokeMiterlimit="10"
    />
    <path
      d="M14.7407 22.8418L7.22266 29.9086"
      stroke="#FF7A00"
      strokeWidth="1.89723"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1575 34.8683V28.2828L18.0182 25.3262"
      stroke="#FF7A00"
      strokeWidth="1.89723"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.7407 18.6332L7.22266 11.5664"
      stroke="#FF7A00"
      strokeWidth="1.89723"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1575 6.60742V13.1929L18.0182 16.1495"
      stroke="#FF7A00"
      strokeWidth="1.89723"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
