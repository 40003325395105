import React from 'react';
import PropTypes from 'prop-types';

export const RightInCircleIcon = ({ width = 20, height = 20 }) => (
  <svg
    className="mb-3"
    width={width}
    height={height}
    viewBox="0 0 90 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M45 90C69.8528 90 90 69.8528 90 45C90 20.1472 69.8528 0 45 0C20.1472 0 0 20.1472 0 45C0 69.8528 20.1472 90 45 90Z"
      fill="#444445"
    />
    <path
      d="M40.5408 62.1661C39.5284 62.1678 38.543 61.8403 37.733 61.2329L37.6828 61.1953L27.1072 53.1053C26.6174 52.7298 26.2063 52.2614 25.8975 51.7271C25.5887 51.1927 25.3881 50.6028 25.3073 49.9909C25.2265 49.3791 25.267 48.7573 25.4265 48.1611C25.5859 47.5648 25.8613 47.0059 26.2368 46.5161C26.6123 46.0263 27.0806 45.6152 27.615 45.3064C28.1493 44.9976 28.7393 44.7971 29.3511 44.7162C29.963 44.6354 30.5848 44.6759 31.181 44.8354C31.7772 44.9949 32.3362 45.2702 32.826 45.6457L39.676 50.8986L55.863 29.781C56.2383 29.2914 56.7064 28.8805 57.2406 28.5718C57.7747 28.2631 58.3644 28.0626 58.976 27.9819C59.5876 27.9011 60.2091 27.9415 60.8051 28.101C61.4011 28.2604 61.9598 28.5356 62.4494 28.911L62.4508 28.912L62.3503 29.0515L62.4535 28.912C63.4412 29.6711 64.0875 30.7911 64.2504 32.0262C64.4133 33.2612 64.0796 34.5105 63.3225 35.4998L44.2831 60.3277C43.8426 60.8998 43.2762 61.3627 42.628 61.6805C41.9797 61.9984 41.2669 62.1626 40.5449 62.1605L40.5408 62.1661Z"
      fill="white"
    />
  </svg>
);

RightInCircleIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};
