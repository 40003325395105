import React from 'react';
export const PageComingSoonIcon = () => {
  return (
    <svg
      width={650}
      height={225}
      viewBox="0 0 710 245"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.6875"
        y="13.5986"
        width="150.189"
        height="220.22"
        rx={15}
        fill="#F5F7FA"
      />
      <rect
        x="266.627"
        y="13.5986"
        width="150.189"
        height="220.22"
        rx={15}
        fill="#F5F7FA"
      />
      <path
        d="M526.872 40.3662C525.551 32.2546 531.003 24.5899 539.099 23.1775L657.178 2.57815C665.339 1.15443 673.109 6.61608 674.532 14.7771L707.395 203.152C708.829 211.373 703.278 219.184 695.043 220.533L574.647 240.252C566.467 241.592 558.751 236.043 557.418 227.861L526.872 40.3662Z"
        fill="#F5F7FA"
      />
      <path
        d="M138.376 12.0947H14.3439C10.5413 12.1001 6.89598 13.6131 4.20715 16.3019C1.51833 18.9907 0.00537928 22.636 0 26.4386V221.346C0.0053815 225.149 1.51833 228.794 4.20715 231.483C6.89598 234.172 10.5413 235.685 14.3439 235.69H138.376C142.179 235.685 145.824 234.172 148.513 231.483C151.201 228.794 152.714 225.149 152.72 221.346V26.4386C152.714 22.636 151.201 18.9907 148.513 16.3019C145.824 13.6131 142.179 12.1001 138.376 12.0947ZM151.032 221.346C151.028 224.702 149.693 227.918 147.321 230.291C144.948 232.664 141.731 233.998 138.376 234.003H14.3439C10.9884 233.998 7.77162 232.664 5.39898 230.291C3.02634 227.918 1.69159 224.702 1.68751 221.346V26.4386C1.69159 23.0832 3.02634 19.8663 5.39898 17.4937C7.77162 15.1211 10.9884 13.7863 14.3439 13.7822H138.376C141.731 13.7863 144.948 15.1211 147.321 17.4937C149.693 19.8663 151.028 23.0832 151.032 26.4386V221.346Z"
        fill="#3F3D56"
      />
      <path
        d="M404.159 235.69H280.127C276.324 235.686 272.678 234.173 269.989 231.484C267.3 228.795 265.787 225.149 265.783 221.346V26.4386C265.787 22.6357 267.3 18.9897 269.989 16.3007C272.678 13.6116 276.324 12.099 280.127 12.0947H404.159C407.962 12.099 411.608 13.6116 414.297 16.3007C416.986 18.9897 418.499 22.6357 418.503 26.4386V221.346C418.499 225.149 416.986 228.795 414.297 231.484C411.608 234.173 407.962 235.686 404.159 235.69ZM280.127 13.7822C276.772 13.786 273.555 15.1207 271.182 17.4934C268.809 19.8661 267.475 23.0831 267.471 26.4386V221.346C267.475 224.702 268.809 227.919 271.182 230.291C273.555 232.664 276.772 233.999 280.127 234.003H404.159C407.515 233.999 410.732 232.664 413.104 230.291C415.477 227.919 416.812 224.702 416.816 221.346V26.4386C416.812 23.0831 415.477 19.8661 413.104 17.4934C410.732 15.1207 407.515 13.786 404.159 13.7822H280.127Z"
        fill="#3F3D56"
      />
      <path
        d="M404.159 235.69H280.127C276.324 235.686 272.678 234.173 269.989 231.484C267.3 228.795 265.787 225.149 265.783 221.346V26.4386C265.787 22.6357 267.3 18.9897 269.989 16.3007C272.678 13.6116 276.324 12.099 280.127 12.0947H404.159C407.962 12.099 411.608 13.6116 414.297 16.3007C416.986 18.9897 418.499 22.6357 418.503 26.4386V221.346C418.499 225.149 416.986 228.795 414.297 231.484C411.608 234.173 407.962 235.686 404.159 235.69ZM280.127 13.7822C276.772 13.786 273.555 15.1207 271.182 17.4934C268.809 19.8661 267.475 23.0831 267.471 26.4386V221.346C267.475 224.702 268.809 227.919 271.182 230.291C273.555 232.664 276.772 233.999 280.127 234.003H404.159C407.515 233.999 410.732 232.664 413.104 230.291C415.477 227.919 416.812 224.702 416.816 221.346V26.4386C416.812 23.0831 415.477 19.8661 413.104 17.4934C410.732 15.1207 407.515 13.786 404.159 13.7822H280.127Z"
        fill="url(#paint0_linear_994_3266)"
        fillOpacity="0.2"
      />
      <path
        d="M226.127 125.158H174.657C174.434 125.158 174.219 125.069 174.061 124.911C173.903 124.753 173.814 124.538 173.814 124.314C173.814 124.091 173.903 123.876 174.061 123.718C174.219 123.56 174.434 123.471 174.657 123.471H226.127C226.35 123.471 226.565 123.56 226.723 123.718C226.881 123.876 226.97 124.091 226.97 124.314C226.97 124.538 226.881 124.753 226.723 124.911C226.565 125.069 226.35 125.158 226.127 125.158Z"
        fill="#3F3D56"
      />
      <path
        d="M215.158 148.299C214.982 148.299 214.809 148.244 214.666 148.141C214.522 148.038 214.415 147.893 214.358 147.726C214.302 147.558 214.299 147.377 214.351 147.209C214.403 147.04 214.506 146.891 214.646 146.784L241.498 126.328C241.808 126.092 242.059 125.787 242.232 125.438C242.405 125.089 242.495 124.705 242.495 124.315C242.495 123.925 242.405 123.541 242.232 123.191C242.059 122.842 241.808 122.538 241.498 122.301L214.646 101.845C214.469 101.71 214.352 101.509 214.322 101.287C214.292 101.066 214.352 100.841 214.487 100.663C214.623 100.486 214.823 100.369 215.045 100.339C215.266 100.309 215.491 100.368 215.669 100.503L242.52 120.959C243.037 121.353 243.456 121.86 243.744 122.442C244.032 123.024 244.182 123.665 244.182 124.315C244.182 124.964 244.032 125.605 243.744 126.187C243.456 126.769 243.037 127.277 242.52 127.67L215.669 148.127C215.522 148.239 215.343 148.299 215.158 148.299Z"
        fill="#3F3D56"
      />
      <path
        d="M491.066 125.158H439.597C439.373 125.158 439.158 125.069 439 124.911C438.842 124.753 438.753 124.538 438.753 124.314C438.753 124.091 438.842 123.876 439 123.718C439.158 123.56 439.373 123.471 439.597 123.471H491.066C491.29 123.471 491.504 123.56 491.662 123.718C491.821 123.876 491.91 124.091 491.91 124.314C491.91 124.538 491.821 124.753 491.662 124.911C491.504 125.069 491.29 125.158 491.066 125.158Z"
        fill="#3F3D56"
      />
      <path
        d="M480.098 148.299C479.921 148.299 479.749 148.244 479.605 148.141C479.462 148.038 479.354 147.893 479.298 147.726C479.241 147.558 479.239 147.377 479.29 147.209C479.342 147.04 479.445 146.891 479.586 146.784L506.437 126.328C506.747 126.092 506.998 125.787 507.171 125.438C507.344 125.089 507.434 124.705 507.434 124.315C507.434 123.925 507.344 123.541 507.171 123.191C506.998 122.842 506.747 122.538 506.437 122.301L479.586 101.845C479.408 101.71 479.292 101.509 479.262 101.287C479.232 101.066 479.291 100.841 479.427 100.663C479.562 100.486 479.763 100.369 479.984 100.339C480.206 100.309 480.43 100.368 480.608 100.503L507.46 120.959C507.976 121.353 508.395 121.86 508.684 122.442C508.972 123.024 509.122 123.665 509.122 124.315C509.122 124.964 508.972 125.605 508.684 126.187C508.395 126.769 507.976 127.277 507.46 127.67L480.608 148.127C480.462 148.239 480.282 148.299 480.098 148.299Z"
        fill="#3F3D56"
      />
      <path
        d="M357.331 90.9866H327.799C325.898 90.9845 324.075 90.2282 322.73 88.8837C321.386 87.5391 320.629 85.7162 320.627 83.8147V55.127C320.629 53.2255 321.386 51.4026 322.73 50.058C324.075 48.7135 325.898 47.9572 327.799 47.9551H357.331C359.232 47.9572 361.055 48.7135 362.4 50.058C363.744 51.4026 364.5 53.2255 364.503 55.127V83.8147C364.5 85.7162 363.744 87.5391 362.4 88.8837C361.055 90.2282 359.232 90.9845 357.331 90.9866Z"
        fill="#FF7A00"
      />
      <path
        d="M362.815 90.5647C362.704 90.5648 362.594 90.5431 362.492 90.5006C362.39 90.4582 362.297 90.396 362.218 90.3175L321.718 49.8172C321.64 49.7389 321.578 49.6458 321.535 49.5435C321.493 49.4411 321.471 49.3314 321.471 49.2206C321.471 49.1098 321.493 49.0001 321.535 48.8978C321.578 48.7954 321.64 48.7024 321.718 48.624C321.796 48.5457 321.889 48.4836 321.992 48.4412C322.094 48.3988 322.204 48.377 322.315 48.377C322.425 48.377 322.535 48.3988 322.637 48.4412C322.74 48.4836 322.833 48.5457 322.911 48.6241L363.411 89.1244C363.529 89.2424 363.61 89.3927 363.642 89.5563C363.675 89.72 363.658 89.8896 363.594 90.0437C363.53 90.1979 363.422 90.3297 363.284 90.4224C363.145 90.5151 362.982 90.5647 362.815 90.5647Z"
        fill="white"
      />
      <path
        d="M322.315 90.5646C322.148 90.5646 321.985 90.5151 321.846 90.4224C321.707 90.3297 321.599 90.1979 321.535 90.0437C321.472 89.8896 321.455 89.72 321.487 89.5563C321.52 89.3927 321.6 89.2424 321.718 89.1244L362.218 48.6241C362.297 48.5457 362.39 48.4836 362.492 48.4412C362.595 48.3988 362.704 48.377 362.815 48.377C362.926 48.377 363.036 48.3988 363.138 48.4412C363.24 48.4836 363.333 48.5457 363.412 48.624C363.49 48.7024 363.552 48.7954 363.595 48.8978C363.637 49.0001 363.659 49.1098 363.659 49.2206C363.659 49.3314 363.637 49.4411 363.595 49.5435C363.552 49.6458 363.49 49.7388 363.412 49.8172L322.911 90.3175C322.833 90.3959 322.74 90.4582 322.638 90.5006C322.535 90.543 322.426 90.5648 322.315 90.5646Z"
        fill="white"
      />
      <path
        d="M369.565 135.283H314.721C311.924 135.283 309.241 134.172 307.263 132.194C305.285 130.216 304.174 127.534 304.174 124.736C304.174 121.939 305.285 119.257 307.263 117.279C309.241 115.301 311.924 114.189 314.721 114.189H369.565C372.362 114.189 375.045 115.301 377.023 117.279C379.001 119.257 380.112 121.939 380.112 124.736C380.112 127.534 379.001 130.216 377.023 132.194C375.045 134.172 372.362 135.283 369.565 135.283ZM314.721 115.877C312.371 115.877 310.118 116.81 308.456 118.472C306.795 120.133 305.862 122.387 305.862 124.736C305.862 127.086 306.795 129.34 308.456 131.001C310.118 132.662 312.371 133.596 314.721 133.596H369.565C371.915 133.596 374.168 132.662 375.83 131.001C377.491 129.34 378.425 127.086 378.425 124.736C378.425 122.387 377.491 120.133 375.83 118.472C374.168 116.81 371.915 115.877 369.565 115.877H314.721Z"
        fill="#3F3D56"
      />
      <path
        d="M369.565 167.347H314.721C311.924 167.347 309.241 166.236 307.263 164.258C305.285 162.28 304.174 159.597 304.174 156.8C304.174 154.003 305.285 151.32 307.263 149.342C309.241 147.364 311.924 146.253 314.721 146.253H369.565C372.362 146.253 375.045 147.364 377.023 149.342C379.001 151.32 380.112 154.003 380.112 156.8C380.112 159.597 379.001 162.28 377.023 164.258C375.045 166.236 372.362 167.347 369.565 167.347ZM314.721 147.94C312.371 147.94 310.118 148.874 308.456 150.535C306.795 152.197 305.862 154.45 305.862 156.8C305.862 159.15 306.795 161.403 308.456 163.064C310.118 164.726 312.371 165.659 314.721 165.659H369.565C371.915 165.659 374.168 164.726 375.83 163.064C377.491 161.403 378.425 159.15 378.425 156.8C378.425 154.45 377.491 152.197 375.83 150.535C374.168 148.874 371.915 147.94 369.565 147.94H314.721Z"
        fill="#3F3D56"
      />
      <path
        d="M369.565 199.409H314.721C311.924 199.409 309.241 198.298 307.263 196.32C305.285 194.342 304.174 191.66 304.174 188.862C304.174 186.065 305.285 183.382 307.263 181.405C309.241 179.427 311.924 178.315 314.721 178.315H369.565C372.362 178.315 375.045 179.427 377.023 181.405C379.001 183.382 380.112 186.065 380.112 188.862C380.112 191.66 379.001 194.342 377.023 196.32C375.045 198.298 372.362 199.409 369.565 199.409ZM314.721 180.003C312.371 180.003 310.118 180.936 308.456 182.598C306.795 184.259 305.862 186.513 305.862 188.862C305.862 191.212 306.795 193.465 308.456 195.127C310.118 196.788 312.371 197.722 314.721 197.722H369.565C371.915 197.722 374.168 196.788 375.83 195.127C377.491 193.465 378.425 191.212 378.425 188.862C378.425 186.513 377.491 184.259 375.83 182.598C374.168 180.936 371.915 180.003 369.565 180.003H314.721Z"
        fill="#3F3D56"
      />
      <path
        d="M658.831 1.13296L536.473 21.4403C532.722 22.0682 529.374 24.1576 527.162 27.2503C524.949 30.3431 524.054 34.1869 524.671 37.9391L556.583 230.217C557.21 233.967 559.3 237.315 562.393 239.528C565.485 241.74 569.329 242.636 573.081 242.018L695.44 221.711C699.19 221.083 702.539 218.994 704.751 215.901C706.963 212.808 707.859 208.964 707.242 205.212L675.33 12.9348C674.702 9.18441 672.613 5.83602 669.52 3.62371C666.427 1.4114 662.583 0.51569 658.831 1.13296ZM705.577 205.489C706.122 208.799 705.332 212.191 703.38 214.92C701.428 217.649 698.473 219.493 695.163 220.046L572.805 240.354C569.494 240.899 566.102 240.109 563.373 238.157C560.644 236.205 558.801 233.25 558.247 229.94L526.336 37.6628C525.79 34.352 526.58 30.96 528.533 28.2309C530.485 25.5018 533.44 23.6584 536.749 23.105L659.108 2.79771C662.418 2.25237 665.81 3.04242 668.539 4.99458C671.268 6.94674 673.112 9.90162 673.665 13.2111L705.577 205.489Z"
        fill="#3F3D56"
      />
      <path
        d="M637.277 79.976L583.173 88.9554C580.414 89.4134 577.585 88.7564 575.31 87.129C573.035 85.5016 571.5 83.0371 571.042 80.2776C570.584 77.5181 571.241 74.6897 572.868 72.4146C574.495 70.1395 576.96 68.6041 579.719 68.1461L633.823 59.1667C636.582 58.7106 639.409 59.3686 641.683 60.996C643.956 62.6235 645.491 65.0872 645.949 67.8456C646.406 70.604 645.75 73.4313 644.124 75.7059C642.498 77.9806 640.035 79.5165 637.277 79.976ZM579.996 69.8109C577.678 70.1961 575.609 71.486 574.242 73.3969C572.875 75.3079 572.324 77.6833 572.708 80.001C573.093 82.3186 574.382 84.3885 576.293 85.7556C578.204 87.1226 580.579 87.6748 582.897 87.2907L637.001 78.3112C639.318 77.926 641.388 76.6361 642.755 74.7252C644.121 72.8142 644.673 70.4387 644.288 68.1211C643.903 65.8035 642.614 63.7336 640.703 62.3666C638.793 60.9995 636.417 60.4474 634.1 60.8315L579.996 69.8109Z"
        fill="#CCCCCC"
      />
      <path
        d="M643.632 118.265L589.528 127.245C588.161 127.472 586.763 127.428 585.413 127.115C584.063 126.803 582.788 126.227 581.661 125.421C580.534 124.615 579.576 123.595 578.844 122.419C578.111 121.243 577.617 119.935 577.39 118.568C577.163 117.201 577.208 115.803 577.521 114.453C577.835 113.103 578.412 111.829 579.218 110.702C580.025 109.576 581.045 108.619 582.222 107.887C583.398 107.154 584.707 106.661 586.074 106.435L640.178 97.4558C642.937 96.9979 645.766 97.6548 648.041 99.2822C650.316 100.91 651.851 103.374 652.309 106.134C652.767 108.893 652.11 111.722 650.483 113.997C648.856 116.272 646.391 117.807 643.632 118.265ZM586.35 108.1C584.033 108.486 581.964 109.776 580.598 111.687C579.232 113.597 578.68 115.972 579.065 118.29C579.449 120.607 580.739 122.677 582.649 124.044C584.559 125.411 586.934 125.963 589.251 125.58L643.355 116.6C645.672 116.215 647.742 114.925 649.108 113.014C650.474 111.103 651.025 108.728 650.641 106.411C650.256 104.093 648.967 102.024 647.057 100.657C645.147 99.2896 642.772 98.7371 640.454 99.1206L586.35 108.1Z"
        fill="#CCCCCC"
      />
      <path
        d="M649.986 156.554L595.882 165.534C594.515 165.761 593.117 165.717 591.767 165.404C590.418 165.092 589.143 164.516 588.015 163.71C586.888 162.904 585.931 161.884 585.198 160.708C584.465 159.532 583.971 158.224 583.744 156.857C583.518 155.49 583.562 154.092 583.876 152.742C584.19 151.392 584.766 150.118 585.573 148.991C586.379 147.864 587.4 146.908 588.576 146.176C589.752 145.443 591.061 144.95 592.428 144.724L646.533 135.745C649.291 135.289 652.118 135.947 654.392 137.574C656.665 139.202 658.2 141.665 658.658 144.424C659.115 147.182 658.459 150.009 656.833 152.284C655.207 154.559 652.744 156.095 649.986 156.554ZM592.705 146.389C591.557 146.579 590.458 146.994 589.47 147.609C588.482 148.224 587.625 149.028 586.948 149.974C586.271 150.92 585.787 151.991 585.524 153.124C585.26 154.258 585.223 155.432 585.413 156.58C585.604 157.728 586.019 158.827 586.634 159.814C587.249 160.802 588.053 161.659 589 162.336C589.946 163.012 591.017 163.496 592.15 163.759C593.284 164.022 594.458 164.06 595.606 163.869L649.71 154.889C650.858 154.699 651.958 154.285 652.945 153.67C653.933 153.055 654.791 152.251 655.468 151.305C656.145 150.358 656.629 149.288 656.893 148.154C657.156 147.021 657.194 145.846 657.003 144.698C656.813 143.55 656.398 142.451 655.782 141.463C655.167 140.476 654.363 139.619 653.416 138.942C652.469 138.265 651.399 137.781 650.265 137.518C649.131 137.255 647.957 137.218 646.809 137.41L592.705 146.389Z"
        fill="#CCCCCC"
      />
      <path
        d="M656.341 194.843L602.237 203.823C600.871 204.049 599.473 204.005 598.124 203.691C596.775 203.378 595.5 202.802 594.374 201.996C593.247 201.19 592.291 200.171 591.558 198.995C590.826 197.819 590.332 196.511 590.105 195.145C589.879 193.778 589.923 192.381 590.237 191.032C590.55 189.683 591.126 188.408 591.932 187.282C592.738 186.155 593.757 185.199 594.933 184.466C596.109 183.734 597.417 183.24 598.783 183.013L652.887 174.034C655.646 173.578 658.473 174.236 660.747 175.863C663.02 177.491 664.555 179.954 665.012 182.713C665.47 185.471 664.814 188.298 663.188 190.573C661.562 192.848 659.099 194.384 656.341 194.843ZM599.06 184.678C597.912 184.869 596.813 185.283 595.825 185.898C594.838 186.514 593.981 187.317 593.304 188.264C591.937 190.175 591.385 192.551 591.77 194.868C592.155 197.186 593.445 199.257 595.356 200.624C597.267 201.991 599.643 202.543 601.961 202.158L656.065 193.178C658.382 192.793 660.452 191.503 661.818 189.592C663.185 187.681 663.737 185.306 663.352 182.988C662.967 180.671 661.678 178.601 659.767 177.234C657.857 175.867 655.481 175.315 653.164 175.699L599.06 184.678Z"
        fill="#CCCCCC"
      />
      <path
        d="M676.348 24.2215L527.353 48.9495C527.244 48.968 527.132 48.9648 527.024 48.9399C526.915 48.9151 526.813 48.8691 526.723 48.8047C526.632 48.7402 526.555 48.6586 526.497 48.5644C526.438 48.4702 526.398 48.3653 526.38 48.2557C526.362 48.1462 526.365 48.0341 526.391 47.926C526.416 47.8178 526.462 47.7157 526.527 47.6255C526.592 47.5353 526.674 47.4588 526.768 47.4003C526.863 47.3418 526.968 47.3026 527.077 47.2847L676.071 22.5568C676.181 22.5382 676.293 22.5415 676.401 22.5663C676.509 22.5912 676.612 22.6371 676.702 22.7016C676.793 22.766 676.869 22.8477 676.928 22.9419C676.987 23.0361 677.027 23.141 677.045 23.2505C677.063 23.3601 677.059 23.4721 677.034 23.5803C677.009 23.6884 676.963 23.7905 676.898 23.8807C676.833 23.971 676.751 24.0475 676.657 24.106C676.562 24.1645 676.457 24.2037 676.348 24.2215Z"
        fill="#3F3D56"
      />
      <path
        d="M543.539 39.003C546.801 39.003 549.445 36.3587 549.445 33.0967C549.445 29.8348 546.801 27.1904 543.539 27.1904C540.277 27.1904 537.633 29.8348 537.633 33.0967C537.633 36.3587 540.277 39.003 543.539 39.003Z"
        fill="#FF7A00"
      />
      <path
        d="M661.183 10.1503L644.536 12.9132C644.315 12.949 644.09 12.896 643.908 12.7658C643.727 12.6356 643.605 12.4388 643.568 12.2185C643.531 11.9982 643.584 11.7725 643.713 11.5906C643.843 11.4088 644.039 11.2858 644.259 11.2485L660.907 8.48556C661.127 8.4497 661.353 8.50269 661.534 8.6329C661.716 8.76311 661.838 8.95992 661.875 9.18022C661.911 9.40051 661.859 9.62633 661.729 9.80817C661.6 9.99 661.403 10.113 661.183 10.1503Z"
        fill="#3F3D56"
      />
      <path
        d="M662.012 15.1444L645.365 17.9073C645.144 17.9431 644.919 17.8901 644.737 17.7599C644.556 17.6297 644.433 17.4329 644.397 17.2126C644.36 16.9923 644.413 16.7666 644.542 16.5847C644.672 16.4029 644.868 16.2799 645.088 16.2426L661.736 13.4797C661.956 13.4439 662.182 13.4969 662.363 13.6271C662.544 13.7573 662.667 13.9541 662.703 14.1744C662.74 14.3946 662.688 14.6204 662.558 14.8022C662.428 14.984 662.232 15.1071 662.012 15.1444Z"
        fill="#3F3D56"
      />
      <path
        d="M662.841 20.1395L646.194 22.9024C646.084 22.9209 645.972 22.9177 645.864 22.8928C645.756 22.8679 645.653 22.822 645.563 22.7575C645.473 22.6931 645.396 22.6114 645.337 22.5172C645.278 22.423 645.239 22.3181 645.22 22.2086C645.202 22.0991 645.206 21.987 645.231 21.8789C645.256 21.7708 645.302 21.6687 645.367 21.5785C645.432 21.4883 645.514 21.4117 645.608 21.3533C645.703 21.2948 645.808 21.2555 645.917 21.2377L662.565 18.4748C662.674 18.4562 662.786 18.4594 662.894 18.4843C663.003 18.5091 663.105 18.5551 663.195 18.6195C663.286 18.684 663.363 18.7657 663.421 18.8599C663.48 18.9541 663.52 19.0589 663.538 19.1685C663.556 19.278 663.553 19.3901 663.527 19.4983C663.502 19.6064 663.456 19.7085 663.391 19.7987C663.326 19.8889 663.244 19.9655 663.15 20.0239C663.056 20.0824 662.951 20.1217 662.841 20.1395Z"
        fill="#3F3D56"
      />
      <path
        d="M108.001 139.081H44.719C42.8176 139.078 40.9946 138.322 39.6501 136.978C38.3055 135.633 37.5492 133.81 37.5471 131.909V67.7833C37.5492 65.8818 38.3055 64.0588 39.6501 62.7143C40.9946 61.3697 42.8176 60.6134 44.719 60.6113H108.001C109.902 60.6135 111.725 61.3697 113.07 62.7143C114.414 64.0588 115.171 65.8818 115.173 67.7833V131.909C115.171 133.81 114.414 135.633 113.07 136.978C111.725 138.322 109.902 139.078 108.001 139.081Z"
        fill="#FF7A00"
      />
      <path
        d="M103.36 186.753H48.5159C45.7187 186.753 43.0361 185.642 41.0581 183.664C39.0802 181.686 37.969 179.003 37.969 176.206C37.969 173.409 39.0802 170.726 41.0581 168.748C43.0361 166.77 45.7187 165.659 48.5159 165.659H103.36C106.157 165.659 108.84 166.77 110.818 168.748C112.796 170.726 113.907 173.409 113.907 176.206C113.907 179.003 112.796 181.686 110.818 183.664C108.84 185.642 106.157 186.753 103.36 186.753ZM48.5159 167.347C46.1663 167.347 43.9128 168.28 42.2514 169.942C40.5899 171.603 39.6565 173.856 39.6565 176.206C39.6565 178.556 40.5899 180.809 42.2514 182.471C43.9128 184.132 46.1663 185.066 48.5159 185.066H103.36C105.71 185.066 107.963 184.132 109.625 182.471C111.286 180.809 112.219 178.556 112.219 176.206C112.219 173.856 111.286 171.603 109.625 169.942C107.963 168.28 105.71 167.347 103.36 167.347H48.5159Z"
        fill="#3F3D56"
      />
      <path
        d="M113.485 137.815C113.374 137.815 113.265 137.793 113.162 137.751C113.06 137.708 112.967 137.646 112.889 137.568L38.6381 63.3172C38.5589 63.239 38.496 63.1459 38.453 63.0434C38.4099 62.9408 38.3876 62.8307 38.3872 62.7194C38.3869 62.6082 38.4085 62.4979 38.451 62.3951C38.4934 62.2922 38.5557 62.1988 38.6344 62.1201C38.7131 62.0415 38.8065 61.9792 38.9094 61.9368C39.0122 61.8944 39.1225 61.8727 39.2337 61.8731C39.345 61.8734 39.4551 61.8958 39.5576 61.9389C39.6602 61.9819 39.7533 62.0449 39.8314 62.1241L114.082 136.375C114.2 136.493 114.28 136.643 114.313 136.807C114.345 136.97 114.329 137.14 114.265 137.294C114.201 137.448 114.093 137.58 113.954 137.673C113.815 137.765 113.652 137.815 113.485 137.815Z"
        fill="white"
      />
      <path
        d="M39.2346 137.815C39.0677 137.815 38.9046 137.765 38.7659 137.673C38.6271 137.58 38.519 137.448 38.4551 137.294C38.3912 137.14 38.3745 136.97 38.4071 136.807C38.4396 136.643 38.5199 136.493 38.6379 136.375L112.888 62.1241C112.967 62.0449 113.06 61.9819 113.162 61.9389C113.265 61.8958 113.375 61.8734 113.486 61.8731C113.597 61.8727 113.708 61.8944 113.81 61.9368C113.913 61.9792 114.007 62.0415 114.085 62.1201C114.164 62.1988 114.226 62.2922 114.269 62.3951C114.311 62.4979 114.333 62.6082 114.333 62.7194C114.332 62.8307 114.31 62.9407 114.267 63.0433C114.224 63.1459 114.161 63.239 114.082 63.3172L39.8313 137.568C39.753 137.646 39.66 137.708 39.5576 137.751C39.4552 137.793 39.3454 137.815 39.2346 137.815Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_994_3266"
          x1="342.143"
          y1="12.0947"
          x2="342.143"
          y2="235.69"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} />
        </linearGradient>
      </defs>
    </svg>
  );
};
