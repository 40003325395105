import PropTypes from 'prop-types';
import React from 'react';

export const LinkIcon = ({ width = 20, height = 20, color = '#444445' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8838 6.75902L12.0623 7.93753C14.3404 10.2156 14.3404 13.909 12.0623 16.1871L11.7677 16.4817C9.48963 18.7598 5.79617 18.7598 3.51811 16.4817C1.24006 14.2037 1.24006 10.5102 3.51811 8.23216L4.69663 9.41072C3.06945 11.0379 3.06945 13.676 4.69663 15.3032C6.32381 16.9304 8.96196 16.9304 10.5892 15.3032L10.8838 15.0086C12.511 13.3814 12.511 10.7432 10.8838 9.11605L9.7053 7.93753L10.8838 6.75902ZM16.4817 11.7677L15.3032 10.5892C16.9304 8.96196 16.9304 6.32381 15.3032 4.69663C13.676 3.06945 11.0379 3.06945 9.41072 4.69663L9.11605 4.99126C7.48886 6.61844 7.48886 9.25663 9.11605 10.8838L10.2945 12.0623L9.11605 13.2408L7.93753 12.0623C5.65947 9.7843 5.65947 6.09081 7.93753 3.81275L8.23216 3.51811C10.5102 1.24006 14.2037 1.24006 16.4817 3.51811C18.7598 5.79617 18.7598 9.48963 16.4817 11.7677Z"
      fill={color}
    />
  </svg>
);

LinkIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};
