import React from 'react';
export const HoldIcon = () => {
  return (
    <svg
      width={33}
      height={33}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16.5" cy="16.5" r={16} fill="white" stroke="#DDE4F0" />
      <path
        d="M16 25.3327C11.3976 25.3327 7.66663 21.6017 7.66663 16.9993C7.66663 12.397 11.3976 8.66602 16 8.66602C20.6023 8.66602 24.3333 12.397 24.3333 16.9993C24.3333 21.6017 20.6023 25.3327 16 25.3327ZM13.5 14.4993V19.4993H18.5V14.4993H13.5Z"
        fill="#E32235"
      />
    </svg>
  );
};
