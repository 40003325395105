import React from 'react';
export const InvalidProcessorIcon = () => {
  return (
    <svg
      width={35}
      height={30}
      viewBox="0 0 35 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5968 25.9366L19.1696 1.01052C18.3612 -0.336839 16.6096 -0.336839 15.8012 1.01052L0.37399 25.9366C-0.569159 27.4861 0.37399 29.6418 2.05818 29.6418H32.9126C34.5968 29.6418 35.6073 27.4861 34.5968 25.9366ZM17.4853 24.2518C16.34 24.2518 15.4642 23.376 15.4642 22.2307C15.4642 21.0855 16.34 20.2097 17.4853 20.2097C18.6305 20.2097 19.5063 21.0855 19.5063 22.2307C19.5063 23.376 18.6305 24.2518 17.4853 24.2518ZM19.5063 18.1895C19.5063 18.5937 19.2368 18.8631 18.8326 18.8631H16.1379C15.7337 18.8631 15.4642 18.5937 15.4642 18.1895V9.43165C15.4642 9.02745 15.7337 8.75798 16.1379 8.75798H18.8326C19.2368 8.75798 19.5063 9.02745 19.5063 9.43165V18.1895Z"
        fill="#D54742"
      />
    </svg>
  );
};
