import React from 'react';
export const OpenLinkIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.33333 3V4.66667H2.16667V13.8333H11.3333V9.66667H13V14.6667C13 15.1269 12.6269 15.5 12.1667 15.5H1.33333C0.8731 15.5 0.5 15.1269 0.5 14.6667V3.83333C0.5 3.3731 0.8731 3 1.33333 3H6.33333ZM15.5 0.5V7.16667H13.8333L13.8333 3.34417L7.33925 9.83925L6.16074 8.66075L12.6541 2.16667H8.83333V0.5H15.5Z"
        fill="#FF7A00"
      />
    </svg>
  );
};
