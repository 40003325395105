import React from 'react';

export const CollapseSidebarIconLeft = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.75 1.75H7.75V12.25H1.75V1.75ZM12.25 12.25H9.25V1.75H12.25V12.25ZM1 0.25C0.58579 0.25 0.25 0.58579 0.25 1V13C0.25 13.4142 0.58579 13.75 1 13.75H13C13.4142 13.75 13.75 13.4142 13.75 13V1C13.75 0.58579 13.4142 0.25 13 0.25H1ZM3.25 7L6.25 4.375V9.625L3.25 7Z"
      fill="#444445"
    />
  </svg>
);
