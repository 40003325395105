import React from 'react';
export const ProcessorDashbaordIcon = () => {
  return (
    <svg
      width={35}
      height={35}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_874_23728)">
        <path
          d="M19.4376 5.54512L20.2705 4.71226L19.0064 3.44814C25.3496 4.09782 30.46 8.93719 31.4542 15.2356L32.6176 15.0481C31.5263 8.12141 25.8466 2.83478 18.8592 2.24251L20.2729 0.828857L19.4376 0L17.0816 2.35599C16.8517 2.58617 16.8517 2.95895 17.0816 3.18886L19.4376 5.54512Z"
          fill="#3D6674"
        />
        <path
          d="M3.18886 17.9144L5.54512 15.5582L4.71226 14.7253L3.44814 15.9894C4.09782 9.64641 8.93719 4.53601 15.2356 3.5416L15.0481 2.37842C8.12141 3.46976 2.83478 9.14947 2.24224 16.1368L0.828857 14.7232L0 15.5584L2.35599 17.9144C2.58617 18.1443 2.95895 18.1443 3.18886 17.9144Z"
          fill="#3D6674"
        />
        <path
          d="M15.5582 29.4507L14.7253 30.2836L15.9894 31.5477C9.64641 30.8978 4.53601 26.0584 3.54186 19.7603L2.37842 19.9474C3.4695 26.8744 9.14947 32.1611 16.1368 32.7533L14.7232 34.167L15.556 34.9999L17.912 32.6439C18.1419 32.4137 18.1419 32.0409 17.912 31.811L15.5582 29.4507Z"
          fill="#3D6674"
        />
        <path
          d="M34.9958 19.4376L32.6398 17.0816C32.4099 16.8517 32.0369 16.8517 31.807 17.0816L29.451 19.4376L30.2838 20.2705L31.5479 19.0064C30.898 25.3496 26.0586 30.46 19.7605 31.4542L19.9477 32.6173C26.8747 31.5263 32.1613 25.8466 32.7536 18.8592L34.1672 20.2729L34.9958 19.4376Z"
          fill="#3D6674"
        />
        <path
          d="M17.4979 12.7856C14.8954 12.7856 12.7859 14.8952 12.7859 17.4976C12.7859 20.1001 14.8954 22.2099 17.4979 22.2099C20.1003 22.2099 22.2101 20.1001 22.2101 17.4976C22.2072 14.8965 20.0993 12.7886 17.4979 12.7856ZM15.7309 18.0867H14.5528V16.9086H15.7309V18.0867ZM18.0869 18.0867H16.9088V16.9086H18.0869V18.0867ZM20.4429 18.0867H19.2651V16.9086H20.4429V18.0867Z"
          fill="#3D6674"
        />
        <path
          d="M21.4545 24.4866L22.9224 23.4206L25.2319 24.3928L26.4458 22.7226L24.8065 20.8264C25.0533 20.0683 25.1211 19.8592 25.3673 19.1006L27.8082 18.5305V16.4653L25.3673 15.8952C25.1211 15.1365 25.0533 14.9275 24.8065 14.1694L26.4458 12.2735L25.2319 10.6029L22.9224 11.5755L21.4545 10.5092L21.666 8.01169L19.7023 7.37402L18.4064 9.51853H16.5909L15.2952 7.37402L13.3302 8.01489L13.5417 10.5121L12.0738 11.5784L9.76428 10.6029L8.55037 12.2735L10.1894 14.1694C9.94266 14.9275 9.8751 15.1365 9.6289 15.8952L7.18799 16.4653V18.5305L9.6289 19.1006C9.8751 19.8592 9.94266 20.0683 10.1894 20.8264L8.55037 22.7226L9.76428 24.3928L12.0738 23.4206L13.5417 24.4866L13.3302 26.9841L15.2939 27.622L16.5898 25.4772H18.404L19.6999 27.622L21.6636 26.9841L21.4545 24.4866ZM17.498 23.388C14.245 23.388 11.6078 20.7511 11.6078 17.4979C11.6078 14.2449 14.245 11.6078 17.498 11.6078C20.7512 11.6078 23.3881 14.2449 23.3881 17.4979C23.3846 20.7495 20.7496 23.3845 17.498 23.388Z"
          fill="#3D6674"
        />
      </g>
      <defs>
        <clipPath id="clip0_874_23728">
          <rect width={35} height={35} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
