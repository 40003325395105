import React from 'react';
export const ConfirmScheduleDeploymentIcon = () => {
  return (
    <svg
      width={82}
      height={133}
      viewBox="0 0 82 133"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M77.7393 132.474H2.37978C1.74884 132.473 1.14395 132.222 0.697805 131.776C0.251665 131.33 0.000711055 130.725 0 130.094V2.37978C0.000711055 1.74884 0.251665 1.14395 0.697805 0.697805C1.14395 0.251665 1.74884 0.000711055 2.37978 0H77.7393C78.3703 0.000711055 78.9752 0.251665 79.4213 0.697805C79.8674 1.14395 80.1184 1.74884 80.1191 2.37978V130.094C80.1184 130.725 79.8674 131.33 79.4213 131.776C78.9752 132.222 78.3703 132.473 77.7393 132.474ZM2.37978 0.528839C1.88905 0.529411 1.41859 0.724604 1.0716 1.0716C0.724604 1.41859 0.529411 1.88905 0.528839 2.37978V130.094C0.529411 130.585 0.724604 131.056 1.0716 131.403C1.41859 131.75 1.88905 131.945 2.37978 131.945H77.7393C78.23 131.945 78.7005 131.75 79.0475 131.403C79.3945 131.056 79.5897 130.585 79.5902 130.094V2.37978C79.5897 1.88905 79.3945 1.41859 79.0475 1.0716C78.7005 0.724604 78.23 0.529411 77.7393 0.528839H2.37978Z"
        fill="#3F3D56"
      />
      <path
        d="M50.3716 7.66751H29.7468C29.6767 7.66751 29.6095 7.63965 29.5599 7.59006C29.5103 7.54048 29.4824 7.47322 29.4824 7.40309C29.4824 7.33296 29.5103 7.26571 29.5599 7.21612C29.6095 7.16653 29.6767 7.13867 29.7468 7.13867H50.3716C50.4417 7.13867 50.5089 7.16653 50.5585 7.21612C50.6081 7.26571 50.636 7.33296 50.636 7.40309C50.636 7.47322 50.6081 7.54048 50.5585 7.59006C50.5089 7.63965 50.4417 7.66751 50.3716 7.66751Z"
        fill="#3F3D56"
      />
      <path
        d="M69.0135 122.955H10.8412C10.2103 122.954 9.60537 122.703 9.15923 122.257C8.71309 121.811 8.46214 121.206 8.46143 120.575V16.9227C8.46214 16.2918 8.71309 15.6869 9.15923 15.2408C9.60537 14.7946 10.2103 14.5437 10.8412 14.543H69.0135C69.6444 14.5437 70.2493 14.7946 70.6954 15.2408C71.1416 15.6869 71.3925 16.2918 71.3932 16.9227V120.575C71.3925 121.206 71.1416 121.811 70.6954 122.257C70.2493 122.703 69.6444 122.954 69.0135 122.955ZM10.8412 15.0718C10.3505 15.0724 9.88002 15.2676 9.53302 15.6146C9.18603 15.9616 8.99084 16.432 8.99026 16.9227V120.575C8.99084 121.066 9.18603 121.536 9.53302 121.883C9.88002 122.23 10.3505 122.426 10.8412 122.426H69.0135C69.5042 122.426 69.9747 122.23 70.3217 121.883C70.6686 121.536 70.8638 121.066 70.8644 120.575V16.9227C70.8638 16.432 70.6686 15.9616 70.3217 15.6146C69.9747 15.2676 69.5042 15.0724 69.0135 15.0718H10.8412Z"
        fill="#3F3D56"
      />
      <path
        d="M80.7802 27.2358C80.4647 27.2354 80.1623 27.11 79.9392 26.8869C79.7161 26.6638 79.5907 26.3614 79.5903 26.0459V21.0219C79.5903 20.7063 79.7157 20.4037 79.9389 20.1805C80.162 19.9574 80.4646 19.832 80.7802 19.832C81.0958 19.832 81.3985 19.9574 81.6216 20.1805C81.8448 20.4037 81.9701 20.7063 81.9701 21.0219V26.0459C81.9698 26.3614 81.8443 26.6638 81.6212 26.8869C81.3981 27.11 81.0957 27.2354 80.7802 27.2358ZM80.7802 20.3609C80.605 20.3611 80.4369 20.4308 80.313 20.5547C80.1891 20.6786 80.1194 20.8467 80.1192 21.0219V26.0459C80.1192 26.2212 80.1888 26.3894 80.3128 26.5133C80.4368 26.6373 80.6049 26.7069 80.7802 26.7069C80.9555 26.7069 81.1237 26.6373 81.2477 26.5133C81.3716 26.3894 81.4413 26.2212 81.4413 26.0459V21.0219C81.4411 20.8467 81.3714 20.6786 81.2475 20.5547C81.1235 20.4308 80.9555 20.3611 80.7802 20.3609Z"
        fill="#3F3D56"
      />
      <path
        d="M80.7802 35.4311C80.4647 35.4307 80.1623 35.3053 79.9392 35.0822C79.7161 34.8591 79.5907 34.5567 79.5903 34.2412V29.2172C79.5903 28.9017 79.7157 28.599 79.9389 28.3759C80.162 28.1527 80.4646 28.0273 80.7802 28.0273C81.0958 28.0273 81.3985 28.1527 81.6216 28.3759C81.8448 28.599 81.9701 28.9017 81.9701 29.2172V34.2412C81.9698 34.5567 81.8443 34.8591 81.6212 35.0822C81.3981 35.3053 81.0957 35.4307 80.7802 35.4311ZM80.7802 28.5562C80.605 28.5564 80.4369 28.6261 80.313 28.75C80.1891 28.8739 80.1194 29.042 80.1192 29.2172V34.2412C80.1192 34.4165 80.1888 34.5847 80.3128 34.7086C80.4368 34.8326 80.6049 34.9022 80.7802 34.9022C80.9555 34.9022 81.1237 34.8326 81.2477 34.7086C81.3716 34.5847 81.4413 34.4165 81.4413 34.2412V29.2172C81.4411 29.042 81.3714 28.8739 81.2475 28.75C81.1235 28.6261 80.9555 28.5564 80.7802 28.5562Z"
        fill="#3F3D56"
      />
      <path
        d="M59.7592 56.0598H20.0963C19.6755 56.0598 19.272 55.8926 18.9744 55.5951C18.6769 55.2975 18.5098 54.894 18.5098 54.4732C18.5098 54.0525 18.6769 53.6489 18.9744 53.3514C19.272 53.0539 19.6755 52.8867 20.0963 52.8867H59.7592C60.18 52.8867 60.5835 53.0539 60.881 53.3514C61.1786 53.6489 61.3457 54.0525 61.3457 54.4732C61.3457 54.894 61.1786 55.2975 60.881 55.5951C60.5835 55.8926 60.18 56.0598 59.7592 56.0598Z"
        fill="#CCCCCC"
      />
      <path
        d="M59.7592 63.1984H20.0963C19.6755 63.1984 19.272 63.0313 18.9744 62.7337C18.6769 62.4362 18.5098 62.0327 18.5098 61.6119C18.5098 61.1911 18.6769 60.7876 18.9744 60.4901C19.272 60.1925 19.6755 60.0254 20.0963 60.0254H59.7592C60.18 60.0254 60.5835 60.1925 60.881 60.4901C61.1786 60.7876 61.3457 61.1911 61.3457 61.6119C61.3457 62.0327 61.1786 62.4362 60.881 62.7337C60.5835 63.0313 60.18 63.1984 59.7592 63.1984Z"
        fill="#CCCCCC"
      />
      <path
        d="M59.7592 70.3371H20.0963C19.6755 70.3371 19.272 70.1699 18.9744 69.8724C18.6769 69.5749 18.5098 69.1713 18.5098 68.7506C18.5098 68.3298 18.6769 67.9263 18.9744 67.6287C19.272 67.3312 19.6755 67.1641 20.0963 67.1641H59.7592C60.18 67.1641 60.5835 67.3312 60.881 67.6287C61.1786 67.9263 61.3457 68.3298 61.3457 68.7506C61.3457 69.1713 61.1786 69.5749 60.881 69.8724C60.5835 70.1699 60.18 70.3371 59.7592 70.3371Z"
        fill="#CCCCCC"
      />
      <path
        d="M45.6397 48.9207H32.8139C32.0425 48.9207 31.3027 48.6143 30.7572 48.0688C30.2117 47.5234 29.9053 46.7835 29.9053 46.0121C29.9053 45.2407 30.2117 44.5009 30.7572 43.9554C31.3027 43.41 32.0425 43.1035 32.8139 43.1035H45.6397C46.4111 43.1035 47.1509 43.41 47.6963 43.9554C48.2418 44.5009 48.5483 45.2407 48.5483 46.0121C48.5483 46.7835 48.2418 47.5234 47.6963 48.0688C47.1509 48.6143 46.4111 48.9207 45.6397 48.9207Z"
        fill="#FF7A00"
      />
      <path
        d="M27.755 94.3948C26.6156 94.3948 25.5018 94.0569 24.5544 93.4239C23.6071 92.7909 22.8687 91.8912 22.4327 90.8385C21.9966 89.7859 21.8826 88.6275 22.1048 87.51C22.3271 86.3925 22.8758 85.366 23.6815 84.5604C24.4871 83.7547 25.5136 83.206 26.6311 82.9837C27.7486 82.7615 28.907 82.8755 29.9596 83.3116C31.0123 83.7476 31.912 84.486 32.545 85.4334C33.178 86.3807 33.5159 87.4945 33.5159 88.6339C33.5142 90.1613 32.9067 91.6256 31.8267 92.7056C30.7467 93.7856 29.2824 94.3931 27.755 94.3948ZM27.755 83.4795C26.7356 83.4795 25.739 83.7818 24.8914 84.3481C24.0437 84.9145 23.383 85.7195 22.9929 86.6614C22.6028 87.6033 22.5007 88.6397 22.6996 89.6395C22.8985 90.6394 23.3894 91.5578 24.1103 92.2787C24.8311 92.9996 25.7496 93.4905 26.7494 93.6894C27.7493 93.8883 28.7857 93.7862 29.7275 93.396C30.6694 93.0059 31.4744 92.3453 32.0408 91.4976C32.6072 90.65 32.9095 89.6534 32.9095 88.6339C32.9095 87.957 32.7762 87.2868 32.5171 86.6614C32.2581 86.036 31.8784 85.4678 31.3998 84.9892C30.9212 84.5105 30.3529 84.1309 29.7276 83.8718C29.1022 83.6128 28.4319 83.4795 27.755 83.4795Z"
        fill="#3F3D56"
      />
      <path
        d="M27.7643 90.6277C27.6775 90.6276 27.5916 90.6105 27.5114 90.5772C27.4313 90.5439 27.3585 90.4952 27.2973 90.4338L25.1805 88.3168C25.1191 88.2555 25.0704 88.1826 25.0371 88.1024C25.0039 88.0222 24.9868 87.9363 24.9868 87.8494C24.9868 87.7626 25.0039 87.6767 25.0372 87.5965C25.0705 87.5163 25.1192 87.4434 25.1806 87.3821L25.2901 87.2724C25.4142 87.1487 25.5823 87.0793 25.7575 87.0793C25.9328 87.0793 26.1008 87.1487 26.225 87.2724L27.3223 88.3695C27.3632 88.4104 27.4126 88.4419 27.4669 88.4617C27.5213 88.4815 27.5793 88.4892 27.6369 88.4842C27.6945 88.4792 27.7503 88.4617 27.8005 88.4329C27.8506 88.4041 27.8939 88.3647 27.9272 88.3174L32.6067 81.6671C32.7077 81.5238 32.8614 81.4265 33.0341 81.3965C33.2068 81.3665 33.3844 81.4062 33.5278 81.507L33.6551 81.5966C33.7261 81.6466 33.7865 81.7101 33.833 81.7834C33.8794 81.8567 33.911 81.9385 33.9258 82.024C33.9407 82.1096 33.9386 82.1972 33.9195 82.2819C33.9005 82.3666 33.865 82.4467 33.8151 82.5177L28.3052 90.347C28.2498 90.4259 28.1778 90.4917 28.0942 90.5398C28.0106 90.5879 27.9174 90.617 27.8213 90.6251C27.8022 90.6269 27.7832 90.6277 27.7643 90.6277Z"
        fill="#FF7A00"
      />
      <path
        d="M50.4948 94.3948C49.3554 94.3948 48.2416 94.0569 47.2942 93.4239C46.3468 92.7909 45.6084 91.8912 45.1724 90.8385C44.7364 89.7859 44.6223 88.6275 44.8446 87.51C45.0669 86.3925 45.6155 85.366 46.4212 84.5604C47.2269 83.7547 48.2534 83.206 49.3709 82.9837C50.4884 82.7615 51.6467 82.8755 52.6994 83.3116C53.752 83.7476 54.6518 84.486 55.2848 85.4334C55.9178 86.3807 56.2557 87.4945 56.2557 88.6339C56.2539 90.1613 55.6464 91.6256 54.5664 92.7056C53.4864 93.7856 52.0221 94.3931 50.4948 94.3948ZM50.4948 83.4795C49.4753 83.4795 48.4788 83.7818 47.6311 84.3481C46.7835 84.9145 46.1228 85.7195 45.7327 86.6614C45.3425 87.6033 45.2404 88.6397 45.4393 89.6395C45.6382 90.6394 46.1291 91.5578 46.85 92.2787C47.5709 92.9996 48.4893 93.4905 49.4892 93.6894C50.489 93.8883 51.5254 93.7862 52.4673 93.396C53.4092 93.0059 54.2142 92.3453 54.7806 91.4976C55.3469 90.65 55.6492 89.6534 55.6492 88.6339C55.6492 87.957 55.5159 87.2868 55.2569 86.6614C54.9979 86.036 54.6182 85.4678 54.1395 84.9892C53.6609 84.5105 53.0927 84.1309 52.4673 83.8718C51.8419 83.6128 51.1717 83.4795 50.4948 83.4795Z"
        fill="#3F3D56"
      />
      <path
        d="M52.3406 91.2701H52.3395C52.2527 91.2702 52.1667 91.2531 52.0865 91.2197C52.0063 91.1863 51.9335 91.1373 51.8724 91.0756L50.7755 89.9758C50.6999 89.9035 50.5993 89.8631 50.4947 89.863C50.39 89.863 50.2894 89.9033 50.2137 89.9756L49.1168 91.0759C49.0556 91.1375 48.9829 91.1864 48.9027 91.2198C48.8225 91.2531 48.7365 91.2702 48.6497 91.2701H48.6486C48.5619 91.2703 48.476 91.2534 48.3959 91.2202C48.3158 91.1871 48.2431 91.1384 48.1819 91.0769L48.0723 90.9679C47.9484 90.844 47.8787 90.6761 47.8784 90.5009C47.8781 90.3257 47.9473 90.1575 48.0707 90.0332L49.1726 88.9277C49.2465 88.8533 49.288 88.7527 49.2879 88.6478C49.2879 88.5429 49.2464 88.4423 49.1724 88.3679L48.0456 87.2369C47.922 87.1127 47.8528 86.9446 47.853 86.7694C47.8533 86.5942 47.923 86.4262 48.0469 86.3024L48.1567 86.1929C48.2179 86.1315 48.2906 86.0829 48.3707 86.0498C48.4508 86.0167 48.5367 85.9997 48.6234 86H48.6244C48.7112 85.9999 48.7973 86.017 48.8774 86.0504C48.9576 86.0837 49.0304 86.1327 49.0915 86.1944L50.2137 87.32C50.2894 87.3923 50.39 87.4326 50.4946 87.4326C50.5992 87.4326 50.6998 87.3923 50.7755 87.32L51.8977 86.1944C51.9588 86.1327 52.0316 86.0837 52.1118 86.0504C52.192 86.017 52.278 85.9999 52.3648 86H52.3659C52.4526 85.9997 52.5385 86.0167 52.6186 86.0498C52.6987 86.083 52.7714 86.1317 52.8326 86.1932L52.9424 86.3024C53.0662 86.4262 53.1359 86.5942 53.1362 86.7694C53.1364 86.9446 53.0672 87.1127 52.9437 87.2369L51.8166 88.3676C51.7427 88.4421 51.7012 88.5428 51.7012 88.6477C51.7012 88.7526 51.7427 88.8533 51.8166 88.9277L52.9183 90.0332C53.0419 90.1574 53.1111 90.3255 53.1109 90.5007C53.1106 90.6759 53.0409 90.8438 52.9171 90.9677L52.8072 91.0772C52.746 91.1386 52.6733 91.1872 52.5932 91.2203C52.5131 91.2534 52.4272 91.2703 52.3406 91.2701Z"
        fill="#CCCCCC"
      />
    </svg>
  );
};
