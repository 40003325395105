import React from 'react';
export const UnderLineIcon = () => {
  return (
    <svg
      className="img-fluid seperator"
      width={476}
      height={1}
      viewBox="0 0 476 1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        opacity="0.2"
        x1="-4.37114e-08"
        y1="0.5"
        x2={476}
        y2="0.499958"
        stroke="black"
        strokeDasharray="5 5"
      />
    </svg>
  );
};
