import React from 'react';

export const CanvasYIcon = () => {
  return (
    <svg
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.152 4.528L0.944 0.768H2.512L2.304 4.528H1.152ZM3.568 4.528L3.36 0.768H4.928L4.72 4.528H3.568ZM7.34738 15.44C7.17671 15.44 7.01671 15.4293 6.86738 15.408C6.71804 15.3973 6.59538 15.376 6.49938 15.344V13.936C6.69138 13.9787 6.92604 14 7.20338 14C7.95004 14 8.48871 13.6 8.81938 12.8L9.17138 12.016L5.90738 3.856H7.68338L10.0514 10.064L12.6754 3.856H14.4034L10.4674 13.008C10.2114 13.584 9.93938 14.048 9.65138 14.4C9.36338 14.7627 9.03271 15.024 8.65938 15.184C8.28604 15.3547 7.84871 15.44 7.34738 15.44ZM15.6051 4.528L15.3971 0.768H16.9651L16.7571 4.528H15.6051ZM18.0211 4.528L17.8131 0.768H19.3811L19.1731 4.528H18.0211ZM22.3125 12.112C22.0352 12.112 21.7952 12.016 21.5925 11.824C21.4005 11.6213 21.3045 11.3813 21.3045 11.104C21.3045 10.8267 21.4005 10.592 21.5925 10.4C21.7952 10.1973 22.0352 10.096 22.3125 10.096C22.5898 10.096 22.8245 10.1973 23.0165 10.4C23.2192 10.592 23.3205 10.8267 23.3205 11.104C23.3205 11.3813 23.2192 11.6213 23.0165 11.824C22.8245 12.016 22.5898 12.112 22.3125 12.112ZM22.3125 5.712C22.0352 5.712 21.7952 5.616 21.5925 5.424C21.4005 5.22133 21.3045 4.98133 21.3045 4.704C21.3045 4.42667 21.4005 4.192 21.5925 4C21.7952 3.79733 22.0352 3.696 22.3125 3.696C22.5898 3.696 22.8245 3.79733 23.0165 4C23.2192 4.192 23.3205 4.42667 23.3205 4.704C23.3205 4.98133 23.2192 5.22133 23.0165 5.424C22.8245 5.616 22.5898 5.712 22.3125 5.712Z"
        fill="black"
      />
    </svg>
  );
};
