import * as React from 'react';
export const HelpSupportIcon = props => (
  <svg
    width={265}
    height={277}
    viewBox="0 0 265 277"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M259.736 1.19019e-09H5.25852C3.86426 0.00119758 2.52746 0.555588 1.54156 1.54146C0.555655 2.52734 0.00123276 3.86413 0 5.25839V253.295C0.00124073 254.689 0.555657 256.026 1.54156 257.012C2.52745 257.998 3.86426 258.552 5.25852 258.554H27.5705V257.052H5.25852C4.26249 257.051 3.30759 256.655 2.60329 255.95C1.899 255.246 1.50282 254.291 1.50166 253.295V5.25839C1.50281 4.26236 1.899 3.30746 2.60329 2.60316C3.30759 1.89887 4.26249 1.50269 5.25852 1.50154H259.736C260.733 1.50146 261.689 1.89705 262.394 2.60143C263.1 3.30582 263.497 4.26142 263.499 5.25839V253.295C263.499 254.029 263.283 254.746 262.877 255.357C262.517 255.896 262.028 256.335 261.454 256.636C260.923 256.91 260.334 257.053 259.736 257.052H237.424V258.554H259.736C260.496 258.554 261.247 258.389 261.937 258.07C262.627 257.751 263.24 257.287 263.734 256.708C263.775 256.663 263.814 256.615 263.848 256.564C264.162 256.18 264.416 255.751 264.602 255.291C264.867 254.659 265.002 253.98 265 253.295V5.25839C264.998 3.86322 264.443 2.52572 263.456 1.53975C262.469 0.553779 261.131 -2.96451e-05 259.736 1.19019e-09Z"
      fill="#3F3D56"
    />
    <path
      d="M264.245 21.0781H0.749023V22.5814H264.245V21.0781Z"
      fill="#3F3D56"
    />
    <path
      d="M13.5265 15.785C16.0172 15.785 18.0362 13.766 18.0362 11.2753C18.0362 8.78468 16.0172 6.76562 13.5265 6.76562C11.0359 6.76562 9.01685 8.78468 9.01685 11.2753C9.01685 13.766 11.0359 15.785 13.5265 15.785Z"
      fill="#3F3D56"
    />
    <path
      d="M26.4919 15.785C28.9825 15.785 31.0016 13.766 31.0016 11.2753C31.0016 8.78468 28.9825 6.76562 26.4919 6.76562C24.0012 6.76562 21.9822 8.78468 21.9822 11.2753C21.9822 13.766 24.0012 15.785 26.4919 15.785Z"
      fill="#3F3D56"
    />
    <path
      d="M39.4572 15.785C41.9478 15.785 43.9669 13.766 43.9669 11.2753C43.9669 8.78468 41.9478 6.76562 39.4572 6.76562C36.9666 6.76562 34.9475 8.78468 34.9475 11.2753C34.9475 13.766 36.9666 15.785 39.4572 15.785Z"
      fill="#3F3D56"
    />
    <path
      d="M196.119 55.874H68.8804C66.8012 55.874 64.8073 55.0481 63.3371 53.5779C61.8669 52.1077 61.041 50.1138 61.041 48.0347C61.041 45.9555 61.8669 43.9616 63.3371 42.4914C64.8073 41.0212 66.8012 40.1953 68.8804 40.1953H196.119C198.198 40.1953 200.192 41.0212 201.662 42.4914C203.132 43.9616 203.958 45.9555 203.958 48.0347C203.958 50.1138 203.132 52.1077 201.662 53.5779C200.192 55.0481 198.198 55.874 196.119 55.874ZM68.8804 41.4014C67.1211 41.4014 65.4339 42.1002 64.1899 43.3442C62.9459 44.5882 62.2471 46.2754 62.2471 48.0347C62.2471 49.7939 62.9459 51.4811 64.1899 52.7251C65.4339 53.9691 67.1211 54.6679 68.8804 54.6679H196.119C197.878 54.6679 199.565 53.9691 200.809 52.7251C202.053 51.4811 202.752 49.7939 202.752 48.0347C202.752 46.2754 202.053 44.5882 200.809 43.3442C199.565 42.1002 197.878 41.4014 196.119 41.4014H68.8804Z"
      fill="#3F3D56"
    />
    <path
      d="M228.381 165.381H36.6184C33.9005 165.378 31.2948 164.297 29.3729 162.375C27.4511 160.453 26.37 157.847 26.3669 155.129V93.6206C26.37 90.9027 27.4511 88.297 29.3729 86.3751C31.2948 84.4533 33.9005 83.3722 36.6184 83.3691H228.381C231.099 83.3722 233.704 84.4533 235.626 86.3751C237.548 88.297 238.629 90.9027 238.632 93.6206V155.129C238.629 157.847 237.548 160.453 235.626 162.375C233.704 164.297 231.099 165.378 228.381 165.381ZM36.6184 84.5752C34.2202 84.5779 31.9211 85.5318 30.2253 87.2275C28.5296 88.9233 27.5757 91.2224 27.573 93.6206V155.129C27.5757 157.527 28.5296 159.827 30.2253 161.522C31.9211 163.218 34.2202 164.172 36.6184 164.175H228.381C230.779 164.172 233.078 163.218 234.774 161.522C236.47 159.827 237.423 157.527 237.426 155.129V93.6206C237.423 91.2224 236.47 88.9233 234.774 87.2275C233.078 85.5318 230.779 84.5779 228.381 84.5752H36.6184Z"
      fill="#3F3D56"
    />
    <path
      d="M157.646 121.057H90.7104C89.431 121.057 88.2039 120.548 87.2992 119.644C86.3945 118.739 85.8862 117.512 85.8862 116.232C85.8862 114.953 86.3945 113.726 87.2992 112.821C88.2039 111.916 89.431 111.408 90.7104 111.408H157.646C158.926 111.408 160.153 111.916 161.058 112.821C161.962 113.726 162.471 114.953 162.471 116.232C162.471 117.512 161.962 118.739 161.058 119.644C160.153 120.548 158.926 121.057 157.646 121.057Z"
      fill="#FF7A00"
    />
    <path
      d="M219.758 137.34H90.7104C89.431 137.34 88.2039 136.832 87.2992 135.927C86.3945 135.022 85.8862 133.795 85.8862 132.516C85.8862 131.236 86.3945 130.009 87.2992 129.104C88.2039 128.2 89.431 127.691 90.7104 127.691H219.758C221.038 127.691 222.265 128.2 223.169 129.104C224.074 130.009 224.582 131.236 224.582 132.516C224.582 133.795 224.074 135.022 223.169 135.927C222.265 136.832 221.038 137.34 219.758 137.34Z"
      fill="#FF7A00"
    />
    <path
      d="M58.5078 142.465C68.499 142.465 76.5986 134.365 76.5986 124.374C76.5986 114.383 68.499 106.283 58.5078 106.283C48.5165 106.283 40.417 114.383 40.417 124.374C40.417 134.365 48.5165 142.465 58.5078 142.465Z"
      fill="#FF7A00"
    />
    <path
      d="M219.751 156.937H202.866C201.587 156.937 200.36 156.429 199.455 155.525C198.55 154.62 198.042 153.393 198.042 152.113C198.042 150.834 198.55 149.607 199.455 148.702C200.36 147.797 201.587 147.289 202.866 147.289H219.751C221.03 147.289 222.257 147.797 223.162 148.702C224.067 149.607 224.575 150.834 224.575 152.113C224.575 153.393 224.067 154.62 223.162 155.525C222.257 156.429 221.03 156.937 219.751 156.937Z"
      fill="white"
    />
    <path
      d="M219.751 156.937H202.866C201.587 156.937 200.36 156.429 199.455 155.525C198.55 154.62 198.042 153.393 198.042 152.113C198.042 150.834 198.55 149.607 199.455 148.702C200.36 147.797 201.587 147.289 202.866 147.289H219.751C221.03 147.289 222.257 147.797 223.162 148.702C224.067 149.607 224.575 150.834 224.575 152.113C224.575 153.393 224.067 154.62 223.162 155.525C222.257 156.429 221.03 156.937 219.751 156.937Z"
      fill="#CCCCCC"
    />
    <path
      d="M228.378 194.324H36.6159C33.8983 194.328 31.293 195.409 29.3713 197.331C27.4496 199.253 26.3683 201.858 26.3645 204.576V266.084C26.3683 268.802 27.4496 271.407 29.3713 273.329C31.293 275.251 33.8983 276.332 36.6159 276.336H228.378C231.096 276.333 233.702 275.252 235.624 273.33C237.546 271.408 238.627 268.802 238.63 266.084V204.576C238.627 201.858 237.546 199.252 235.624 197.33C233.702 195.408 231.096 194.327 228.378 194.324ZM237.424 266.084C237.422 268.483 236.468 270.782 234.772 272.478C233.076 274.174 230.777 275.128 228.378 275.13H36.6159C34.2179 275.127 31.9188 274.173 30.2231 272.477C28.5274 270.781 27.5735 268.482 27.5706 266.084V204.576C27.5735 202.178 28.5274 199.879 30.2231 198.183C31.9188 196.487 34.2179 195.533 36.6159 195.53H228.378C230.777 195.532 233.076 196.486 234.772 198.182C236.468 199.878 237.422 202.177 237.424 204.576V266.084Z"
      fill="#3F3D56"
    />
    <path
      d="M157.646 232.014H90.7104C89.431 232.014 88.2039 231.505 87.2992 230.601C86.3945 229.696 85.8862 228.469 85.8862 227.189C85.8862 225.91 86.3945 224.683 87.2992 223.778C88.2039 222.873 89.431 222.365 90.7104 222.365H157.646C158.926 222.365 160.153 222.873 161.058 223.778C161.962 224.683 162.471 225.91 162.471 227.189C162.471 228.469 161.962 229.696 161.058 230.601C160.153 231.505 158.926 232.014 157.646 232.014Z"
      fill="#FF7A00"
    />
    <path
      d="M219.758 248.297H90.7104C89.431 248.297 88.2039 247.789 87.2992 246.884C86.3945 245.979 85.8862 244.752 85.8862 243.473C85.8862 242.193 86.3945 240.966 87.2992 240.061C88.2039 239.157 89.431 238.648 90.7104 238.648H219.758C221.038 238.648 222.265 239.157 223.169 240.061C224.074 240.966 224.582 242.193 224.582 243.473C224.582 244.752 224.074 245.979 223.169 246.884C222.265 247.789 221.038 248.297 219.758 248.297Z"
      fill="#FF7A00"
    />
    <path
      d="M58.5078 253.422C68.499 253.422 76.5986 245.322 76.5986 235.331C76.5986 225.34 68.499 217.24 58.5078 217.24C48.5165 217.24 40.417 225.34 40.417 235.331C40.417 245.322 48.5165 253.422 58.5078 253.422Z"
      fill="#FF7A00"
    />
    <path
      d="M219.751 267.895H202.866C201.587 267.895 200.36 267.386 199.455 266.482C198.55 265.577 198.042 264.35 198.042 263.07C198.042 261.791 198.55 260.564 199.455 259.659C200.36 258.754 201.587 258.246 202.866 258.246H219.751C221.03 258.246 222.257 258.754 223.162 259.659C224.067 260.564 224.575 261.791 224.575 263.07C224.575 264.35 224.067 265.577 223.162 266.482C222.257 267.386 221.03 267.895 219.751 267.895Z"
      fill="#CCCCCC"
    />
  </svg>
);
