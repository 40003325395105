import React from 'react';
import PropTypes from 'prop-types';
export const ForgetPasswordIcon = ({ width = '222', height = '47' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <path
      fill="#E5E5E5"
      d="M23.486 0a23.486 23.486 0 1 0 0 46.972h175.028A23.485 23.485 0 0 0 215.121 6.88 23.485 23.485 0 0 0 198.514 0H23.486Z"
    />
    <path
      fill="#fff"
      d="M23.486 4.753a18.733 18.733 0 0 0 0 37.466h175.028a18.733 18.733 0 0 0 0-37.466H23.486Z"
    />
    <path
      fill="#E32235"
      d="M62.148 34.688H27.324a.838.838 0 1 1 0-1.678h34.824a.84.84 0 0 1 0 1.678ZM106.324 34.967H71.5a.839.839 0 1 1 0-1.678h34.824a.846.846 0 0 1 .597.244.853.853 0 0 1 .247.595.848.848 0 0 1-.247.595.849.849 0 0 1-.597.244ZM150.5 35.247h-34.824a.841.841 0 0 1-.833-.84.838.838 0 0 1 .833-.838H150.5a.842.842 0 0 1 .78.517.83.83 0 0 1 0 .644.852.852 0 0 1-.457.455.842.842 0 0 1-.323.062ZM194.677 35.526h-34.824a.84.84 0 0 1 0-1.677h34.824a.838.838 0 0 1 0 1.678ZM44.659 26.968a6.472 6.472 0 1 0 0-12.944 6.472 6.472 0 0 0 0 12.944ZM89.102 26.968a6.472 6.472 0 1 0 0-12.945 6.472 6.472 0 0 0 0 12.945ZM133.546 26.968a6.472 6.472 0 1 0 0-12.945 6.472 6.472 0 0 0 0 12.945ZM177.988 26.968a6.472 6.472 0 1 0 0-12.945 6.472 6.472 0 0 0 0 12.945Z"
    />
  </svg>
);

ForgetPasswordIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
