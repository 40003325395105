import React from 'react';

export const CanvasXIcon = () => {
  return (
    <svg
      width="23"
      height="13"
      viewBox="0 0 23 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.152 4.528L0.944 0.768H2.512L2.304 4.528H1.152ZM3.568 4.528L3.36 0.768H4.928L4.72 4.528H3.568ZM5.89138 12L9.05938 7.824L6.01938 3.856H7.89138L9.98738 6.64L12.0834 3.856H13.8914L10.8834 7.808L14.0834 12H12.2114L9.95538 8.992L7.69938 12H5.89138ZM15.277 4.528L15.069 0.768H16.637L16.429 4.528H15.277ZM17.693 4.528L17.485 0.768H19.053L18.845 4.528H17.693ZM21.9844 12.112C21.707 12.112 21.467 12.016 21.2644 11.824C21.0724 11.6213 20.9764 11.3813 20.9764 11.104C20.9764 10.8267 21.0724 10.592 21.2644 10.4C21.467 10.1973 21.707 10.096 21.9844 10.096C22.2617 10.096 22.4964 10.1973 22.6884 10.4C22.891 10.592 22.9924 10.8267 22.9924 11.104C22.9924 11.3813 22.891 11.6213 22.6884 11.824C22.4964 12.016 22.2617 12.112 21.9844 12.112ZM21.9844 5.712C21.707 5.712 21.467 5.616 21.2644 5.424C21.0724 5.22133 20.9764 4.98133 20.9764 4.704C20.9764 4.42667 21.0724 4.192 21.2644 4C21.467 3.79733 21.707 3.696 21.9844 3.696C22.2617 3.696 22.4964 3.79733 22.6884 4C22.891 4.192 22.9924 4.42667 22.9924 4.704C22.9924 4.98133 22.891 5.22133 22.6884 5.424C22.4964 5.616 22.2617 5.712 21.9844 5.712Z"
        fill="black"
      />
    </svg>
  );
};
