import React from 'react';
export const DefaultUserIcon = () => {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={25} height={25} rx="12.5" fill="white" />
      <path
        d="M12.5 25C10.0277 25 7.61099 24.2669 5.55538 22.8934C3.49976 21.5199 1.89761 19.5676 0.951511 17.2835C0.00541606 14.9995 -0.242126 12.4861 0.24019 10.0614C0.722505 7.63661 1.91301 5.40933 3.66117 3.66117C5.40933 1.91301 7.63661 0.722505 10.0614 0.24019C12.4861 -0.242126 14.9995 0.00541606 17.2835 0.951511C19.5676 1.89761 21.5199 3.49976 22.8934 5.55538C24.2669 7.61099 25 10.0277 25 12.5C25 15.8152 23.683 18.9946 21.3388 21.3388C18.9946 23.683 15.8152 25 12.5 25ZM12.5 1.92308C10.4081 1.92308 8.36315 2.54341 6.62378 3.70561C4.88441 4.86782 3.52874 6.51971 2.7282 8.45239C1.92766 10.3851 1.7182 12.5117 2.12631 14.5635C2.53443 16.6152 3.54178 18.4998 5.02099 19.979C6.5002 21.4582 8.38483 22.4656 10.4365 22.8737C12.4883 23.2818 14.6149 23.0723 16.5476 22.2718C18.4803 21.4713 20.1322 20.1156 21.2944 18.3762C22.4566 16.6369 23.0769 14.5919 23.0769 12.5C23.0769 9.69483 21.9626 7.00455 19.979 5.02099C17.9955 3.03743 15.3052 1.92308 12.5 1.92308Z"
        fill="#D0D5DF"
      />
      <path
        d="M12.5 0C10.0277 0 7.61099 0.733112 5.55538 2.10663C3.49976 3.48015 1.89761 5.43238 0.951511 7.71645C0.00541606 10.0005 -0.242126 12.5139 0.24019 14.9386C0.722505 17.3634 1.91301 19.5907 3.66117 21.3388C5.40933 23.087 7.63661 24.2775 10.0614 24.7598C12.4861 25.2421 14.9995 24.9946 17.2835 24.0485C19.5676 23.1024 21.5199 21.5002 22.8934 19.4446C24.2669 17.389 25 14.9723 25 12.5C25 9.18479 23.683 6.00537 21.3388 3.66116C18.9946 1.31696 15.8152 0 12.5 0ZM12.5 5.76923C13.2607 5.76923 14.0043 5.9948 14.6368 6.41742C15.2693 6.84004 15.7623 7.44073 16.0534 8.14352C16.3445 8.84632 16.4207 9.61965 16.2723 10.3657C16.1238 11.1118 15.7575 11.7971 15.2196 12.335C14.6817 12.8729 13.9964 13.2392 13.2503 13.3876C12.5043 13.536 11.7309 13.4599 11.0281 13.1688C10.3254 12.8777 9.72466 12.3847 9.30204 11.7522C8.87942 11.1197 8.65385 10.3761 8.65385 9.61538C8.65385 8.59532 9.05907 7.61703 9.78036 6.89574C10.5017 6.17445 11.4799 5.76923 12.5 5.76923ZM12.5 23.0769C11.1056 23.0761 9.72511 22.7996 8.43797 22.2633C7.15082 21.727 5.98243 20.9415 5 19.9519C5.76923 17.3077 8.84616 15.3846 12.5 15.3846C16.1538 15.3846 19.2308 17.3077 20 19.9519C19.0176 20.9415 17.8492 21.727 16.562 22.2633C15.2749 22.7996 13.8944 23.0761 12.5 23.0769Z"
        fill="#D0D5DF"
      />
    </svg>
  );
};
